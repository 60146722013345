@import "styles/shared/colors.css";

.container {
  border-radius: 2px;
  background-color: $white;
  box-shadow: 0 2px 4px 0 $minBlack;
  color: $textPrimary;
  width: 320px;
}

.header {
  padding: 16px;
  border-bottom: 1px solid $grey200;
}

.headerIcon {
  margin-right: 16px;
}

.headerTitle {
  font-size: 18px;
  line-height: 24px;
  vertical-align: text-bottom;
}

.body {
  padding-right: 16px;
  padding-left: 16px;
}

.footer {
  border-top: 1px solid $grey200;
  background-color: $grey150;
  text-align: center;
}

.footerIcon:hover {
  background-color: $white;
}
