@import 'styles/shared/colors.css';

.modalWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 510;
}

.modal {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 600px;
  top: 208px;
  background: #fff;
  border-radius: 6px;
  color: #322c2c;
  display: flex;
  flex-direction: column;
}

.header {
  margin-top: 80px;
  font-size: 33px;
  text-align: center;
  font-weight: 600;
  line-height: normal;
}

.description {
  margin-top: 20px;
  padding: 0 50px;
  font-size: 14px;
  text-align: center;
  color: $newPrimaryText;
  opacity: 0.8;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 40px;
}

.buttons > div {
  margin: 0 6px;
  width: 200px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  box-sizing: border-box;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
}

.cancelButton {
  color: $newBlue;
  border: 1px solid $newBlue;
}

.approveButton {
  color: #fff;
  background: $newBlue;
}

.close {
  position: absolute;
  top: 22px;
  right: 22px;
  height: 19px;
  width: 19px;
  cursor: pointer;
}
