@import 'styles/shared/colors.css';

.dropdownTrigger {
  composes: dropdownTrigger from '../../AutomatedProcessBuilder.css';
  font-weight: 500;
  padding: 1px 4px !important;
  margin: 2px 0;
}

.triggerLabel {
  composes: secondaryCopyAppearance from "../../Rule/Rule.css";
  width: 60px;
  text-align: right;
  margin-right: 8px;
}

.recipientsSelectorRow {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

.multiSelectorContainer {
  max-width: 300px;
}

.addSelectionsButton {
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}

.addUserButton {
  > span[class*=bubble] {
    vertical-align: middle;
  }
}

.resultListContainer {
  padding: 5px;
  text-align: left;
}

.showOverflowBubble {
  margin: 3px 0 3px 8px;
  border: 1px solid $blueGrey200;
  background-color: $white;
  box-shadow: 0 4px 4px rgba(109, 125, 143, 0.09);

  &.invalid {
    border: 1px solid $red500;
    background-color: $red50 !important;
  }
}

.typeahead {
  composes: container from "components/Typeaheads/TypeaheadInput/TypeaheadInput.css";
}

.typeaheadInput {
  composes: input from "components/Typeaheads/TypeaheadInput/TypeaheadInput.css";
}
