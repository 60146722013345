@import "styles/shared/colors.css";

.filterAppearance {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
}

.filterLayout {
  margin-left: 10px;
  vertical-align: middle;
}

.filterButtonAppearance {
  composes: buttonReset from 'styles/shared/resets.css';
  color: $grey800;
  font-size: 13px;
  text-decoration: underline !important;
}

.filterButtonLayout {
  margin-left: 5px;
}
