.relativeContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.centeredIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hamburgerContainer {
  cursor: pointer;
  composes: relativeContainer;
  padding: 0;
  background: unset;
  border: unset;
}

.crossIcon {
  margin: 0 12px 0 auto;
  padding: 0;
  background: unset;
  border: unset;

  & :hover {
    cursor: pointer;
  }

  svg {
    vertical-align: top;
  }
}
