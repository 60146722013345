.SubContainer {
  width: 100%;
}

.SubContainer > div {
  display: inline-block;
  width: 23.5%;
}

.SubContainer > div:first-child,
.SubContainer > div:nth-child(2) {
  margin-right: 2%;
}

.SubContainer > div:last-child {
  margin-left: 2%;
}
