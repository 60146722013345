@import 'styles/shared/colors.css';
@import 'styles/shared/breakpoints.css';

.detailsContainer {
  width: 100%;
}

.fieldsContentBox {
  margin-top: 6px;
  margin-bottom: 20px;
  background-color: $white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15), 0 0 3px 0 rgba(0, 0, 0, 0.15);
}

.fieldsContentBoxBody {
  overflow: hidden;
  min-height: 125px;
}

.boxListItem {
  position: relative;
  padding: 0 16px;
  border-bottom: 1px solid $grey250;
  line-height: 18px;
}

.boxListHalf {
  display: inline-block;
  width: 39.6%;
  composes: boxListItem;
}

.boxListHalfLeft {
  border-right: 1px solid $grey250;
  composes: boxListHalf;
}

.boxListContent {
  overflow: hidden;
  flex-grow: 5;
  order: 2;
}

.ico20 {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  opacity: 0.6;
  font-size: 20px !important;
}

.boxListIcon {
  float: left;
  margin-right: 16px;
  padding-top: 10px;
  composes: ico20;
}

.refundPayment {
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 10px;
  cursor: pointer;
  color: $orangePrimary;
  font-size: 13px;
}

.expandBox {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 20px;
  margin-top: -1px;
  cursor: pointer;
  text-align: center;
  border-top: 1px solid $grey250;
  background-color: $grey150;
  font-size: 13px;
}

.expandBoxIcon {
  margin-top: 0;
  border: none;
  composes: ico20;
}

.boxCard {
  margin-bottom: 8px;
  padding: 12px;
  border: 1px solid $grey250;
  background-color: $grey100;
}

.attachmentImg img {
  width: 60px;
  height: 60px;
}

.attachmentImg span {
  position: relative;
  top: -16px;
  left: -40px;
  text-transform: uppercase;
  color: $white;
  font-size: 12px;
  font-weight: 500;
}

.attachmentBox {
  padding: 16px;
  padding-bottom: 8px;
}

.fieldsLabel {
  margin-bottom: 0;
  color: $textSecondary;
  font-size: 13px;
}

.fieldsContent {
  margin-top: 0;
}

.attachmentName {
  overflow: hidden;
  width: 164px;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  composes: fieldsContent;
}

.attachmentLabel {
  margin-top: 0;
  text-transform: uppercase;
  composes: fieldsLabel;
}

.attachmentLink {
  flex-grow: 1;
  order: 3;
  border-left: 1px solid $grey250;
}

.attachmentView {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  composes: refundPayment;
}

.attachmentDownload {
  composes: refundPayment;
}

.signatureCard {
  position: relative;
  padding: 12px 16px;
  border-bottom: 1px solid $grey250;
}

.signatureNumberSize {
  display: inline-block;
  float: left;
  width: 10px;
  height: 20px;
  margin-right: 16px;
  padding-right: 5px;
}

.signatureNumber {
  width: 10px;
  margin-top: 3px;
  padding-right: 5px;
  padding-left: 5px;
  text-align: center;
  color: $white;
  border-radius: 10px;
  background-color: $blue500;
  font-size: 12px;
}

.signatureWrap {
  line-height: 24px;
}

.signInLine {
  display: inline-block;
  overflow: hidden;
  margin: 0;
  vertical-align: middle;
}

.signatureType {
  margin-right: 8px;
  padding-right: 8px;
  border-right: 1px solid $grey250;
  composes: signInLine;
}

.signatureName {
  max-width: 60%;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: none;
  composes: signInLine;
}

.signatureTimeAgo {
  position: absolute;
  top: 12px;
  right: 14px;
  color: $textSecondary;
  font-size: 13px;
  composes: signInLine;
}

.signatureEmail {
  margin: 0;
  margin-top: 4px;
  color: $textSecondary;
  font-size: 13px;
}

.declineReason {
  display: table;
  margin: 0;
  margin-top: 2px;
  padding: 8px 12px;
  color: $grey700;
  border-radius: 4px;
  background-color: $grey150;
  font-size: 13px;
  font-style: italic;
}

.signatureStatus {
  display: inline-block;
  margin-top: 8px;
  margin-right: 4px;
  margin-bottom: 8px;
  padding: 2px 12px;
  color: $white;
  border-radius: 14px;
  font-size: 12px;
}

.signatureDeclined {
  background-color: $red500;
  composes: signatureStatus;
}

.signatureNotified {
  background-color: $deepPurple300;
  composes: signatureStatus;
}

.signatureOpened {
  background-color: $yellow700;
  composes: signatureStatus;
}

.signatureSigned {
  background-color: $green500;
  composes: signatureStatus;
}

.signaturePending {
  background-color: $yellow700;
  composes: signatureStatus;
}

.signatureSkipped {
  background-color: $newBlue;
  composes: signatureStatus;
}

.signatureLinkBox {
  margin-top: 8px;
  margin-left: 37px;
  display: inline-block;
}

.signatureLinkBox + .signatureLinkBox {
  padding-left: 10px;
  margin-left: 0;
  border-left: solid 1px #e8e8e8;
}

.signatureLink {
  margin-right: 12px;
  color: $orangePrimary;
  font-size: 13px;
  composes: signInLine;
}

.signatureLink:hover {
  cursor: pointer;
}

.signatureViewImg {
  display: default;
  composes: signatureLink;
}

.signatureImg {
  display: inline-block;
  width: 159px;
  max-width: 200px;
  height: 47px;
  max-height: 200px;
  margin-right: 16px;
  padding-right: 16px;
  vertical-align: middle;
}

.signatureImgWithDetails {
  border-right: 1px solid $grey250;
}

.iconLock {
  composes: iconLock from '../SubmissionTable/SubmissionTable.css';
}

.encrypted {
  cursor: pointer;
}

.fieldsList {
  margin: 5px 0 0 0;
  list-style: none;
  padding-left: 0;

  > li {
    padding: 12px 20px;
  }

  .otherFields {
    padding: 8px 20px;
  }
}

.withBottomBorder {
  border-bottom: 1px solid $grey250;
}

.withoutBottomBorder {
  border-bottom: none;
}

.createdTs {
  color: $textSecondary;
  font-size: 12px;
}

.fieldsTitle {
  color: $textPrimary;
  font-size: 14px;
  font-weight: 500;
}

@media screen and (max-width: $breakpoint__small-x),
  (min-width: $breakpoint__medium-xxx) and (max-width: $breakpoint__large-x) {
  .signatureName {
    max-width: 100%;
  }
}
