.loaderBackground {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 0.87;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.loaderBody {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loaderPic {
  height: 100%;
  width: 100%;
}
