@import "styles/shared/colors.css";

.folderTree {
  width: 100%;
  height: 100%;

  span {
    outline: 0;
    overflow: hidden;
    display: inline-block;
    padding: 5px 4px 5px 0;
    height: 21px;
    text-decoration: none;
    vertical-align: top;
    user-select: none;
  }

  a {
    cursor: pointer;
  }
}

.folderIcon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.folderLabel {
  display: flex;
  overflow: hidden;
}

.switcher {
  line-height: 20px;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  border: 0 none;
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
  padding: 0;
  opacity: 0.5;
  background-color: $transparent;
}

.switcherOpen {
  transform: rotate(90deg);
}

.switchLoading {
  background-size: contain;
  background-image: url('icons/loader.gif');
}

/*
  css module does not work very well with nested css,
  checkbox needs to be refactored.
 */
.checkbox {
  width: 13px;
  height: 13px;
  margin: 0 3px;
  background-position: 0 0;

  .checked {
    background-position: -14px 0;
  }

  .indeterminate {
    background-position: -14px -28px;
  }

  .disabled {
    background-position: 0 -56px;
  }

  .checked.disabled {
    background-position: -14px -56px;
  }

  .indeterminate.disabled {
    position: relative;
    background: $grey250;
    border-radius: 3px;
  }

  .indeterminate.disabled::after {
    content: ' ';
    -webkit-transform: scale(1);
    transform: scale(1);
    position: absolute;
    left: 3px;
    top: 5px;
    width: 5px;
    height: 0;
    border: 2px solid $white;
    border-top: 0;
    border-left: 0;
  }
}

.nodeDisabled {
  color: $red500;
  cursor: not-allowed;
}

.title {
  font-weight: 500;
  padding-left: 8px;
  vertical-align: middle;
}

.icon {
  line-height: 20px;
  margin-right: 8px;
  background-size: contain;
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: top;
  border: 0 none;
  cursor: pointer;
}

.iconSmall {
  composes: icon;
  width: 20px;
  height: 20px;
  vertical-align: top;
  margin-left: 15px;
}

.iconDelete {
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  margin-right: 0;
}

.iconDelete:hover {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.previewNode {
  font-weight: 400;
  padding: 16px;
  background-color: $darkWhite;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 2px;
  box-shadow: 0 2px 6px 0 $lightShadow;

  a {
    clear: both;
    float: left;
  }
}

.dropOverNode {
  background-color: $grey150;
}

.delete,
.folder,
.node,
.lobbyAction {
  composes: buttonReset from "styles/shared/resets.css";
}

.node {
  overflow: hidden;
  max-width: 160px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nodeSelected {
  font-weight: 500;
  max-width: 130px; /* making it too wide would cause the text to get shifted left when the delete icon was focused */
}

.lobbyAction {
  align-items: center;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  text-decoration: none;
  white-space: nowrap;
  user-select: none;
  margin-left: 25%;
  padding: 1px 5px;
  border-radius: 50%;
  background-color: $grey400;
  color: $white;
  font-weight: 700;
}

.treeDivider {
  height: 1px;
  background-color: $grey200;
  margin: 15px 0;
}
