@import 'styles/shared/colors.css';

.filterBar {
  display: flex;
  align-items: center;
  padding-left: 38px;
  height: 54px; /* There is JS code dependent on this number, see FILTER_BAR_HEIGHT */
  line-height: 24px;
  border: 1px solid $grey250;
  border-top: 0;
  background: $white;
}

.count {
  margin-left: 15px;
  font-size: 12px;
  color: $grey500;
}

.iconButton {
  composes: buttonReset from 'styles/shared/resets.css';
  margin-left: 15px;
}

.filterIcon {
  height: 13px;
  width: 13px;
}

.sortIcon {
  height: 16px;
  width: 16px;
}
