.ToggleContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
  width: 100%;
}

.ToggleLabelWrapper {
  display: flex;
  width: 100%;
}

.ToggleLabelTypography {
  font-size: 0.85rem; /* 14px */
  font-weight: 700;
  line-height: 1.25rem; /* 20px */
}

.ToggleSwitchLabelTypography {
  font-size: 0.75rem;
  font-weight: 500;
}

.ToggleSwitchWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.OtherOptionsWrapper {
  display: flex;
  flex-direction: column;
  height: 6.25rem; /* 100px */
  width: 100%;
}

textarea.OptionalText {
  margin-top: 0.75rem; /* 12px */
  height: 4.375rem; /* 70px */
  width: 100%;
  background-color: #fff;
  border-radius: 3px !important;
  font-size: 1rem;
  border: none !important;
  box-shadow: 0 0 0 1px #c4cdd5 !important;
  resize: none;
}

.ToggleTooltip {
  margin: 1rem 0; /* 16px 0 */
}
