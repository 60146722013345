@import "styles/shared/colors.css";

.Container {
  position: absolute;
  top: 110px;
  min-width: 500px;
  margin-left: 40px;
  padding: 18px 10px;
  min-height: 116px;
  background: #fff;
  box-shadow: 0 4px 15px rgb(0 0 0 / 50%);
  border-radius: 4px;
  z-index: 21;
  display: grid;
  grid-template-columns: 10px 170px 140px 176px;
  grid-gap: 10px;
  align-items: center;
}

.leftColumn {
  grid-column-start: 2;
  grid-column-end: 3;
  box-sizing: border-box;
}

.middleColumn {
  grid-column-start: 3;
  grid-column-end: 4;
  box-sizing: border-box;
}

.rightColumn {
  grid-column-start: 4;
  grid-column-end: 5;
  box-sizing: border-box;
}

/* .headersWrapper {
  display: contents;
  margin-bottom: 10px;
  gap: 10px;
} */

.header {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: $progressGrey;
}

.emptyState {
  min-height: 60px; /* Header row + 1 filter row */
  grid-column-start: 2;
  display: flex;
  align-items: center;
}

.emptyStateText {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $progressGrey;
}

.filterWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.removeButton {
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
  color: $progressGrey;
}

.closedDropdown {
  max-height: 37px;
  padding: 10px;
  justify-content: space-between;
}

.closedDropdown img {
  margin: 0;
}

.selectedLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expectationInput {
  padding: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  border-radius: 2px;
  border: 1px solid $outlineGrey;
  background-color: $grey50;
  color: $grey850;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.addFilterButton {
  margin-left: 0;
  margin-top: 10px;
  padding: 0;
  border: none;
  background: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
  color: $actionBlue;
  width: 72px;
}

.applyButton {
  width: 124px;
  height: 35px;
  margin: 0;
  margin-left: auto;
  margin-top: 10px;
  padding: 0;
  border: none;
  box-shadow: 1px 3px 3px rgb(0 0 0 / 8%);
  border-radius: 3px;
  cursor: pointer;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: white;
  background: $buttonBlueBackground;
}

.applyButton:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.select {
  min-width: 176px;
}

.expectationFieldSelect {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  min-height: 38px;
  height: fit-content;
  background: $grey50;
  border: 1px solid $outlineGrey;
  border-radius: 2px;
  position: relative;
}
