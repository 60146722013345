.termsContainer {
  max-width: 285px;
  margin: 23px auto;

  text-align: center;

  color: #757575;

  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 17px;
}

.termsLink {
  text-decoration: underline !important;

  color: #757575 !important;
}

.authLinksContainer {
  display: flex;
  justify-content: space-around;

  margin: 30px 10px 0;
  margin-top: 30px;

  letter-spacing: 0.1px;

  font-weight: 500;
}
