@import 'styles/shared/colors.css';

.expansionFieldTotalInfoContainer,
.expansionFieldLabelContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.expansionFieldLabel {
  margin: 0 7px 0 0;
  color: $textSecondary;
  font-size: 12px;
}

.expansionFieldIcon {
  cursor: pointer;
  transition: all 0.3s;
  transform: rotate(0deg);
  font-size: 18px;
}

.expansionFieldIcon_rotate {
  transform: rotate(180deg);
}

.expansionFieldValue {
  margin: 0;
  color: $textPrimary;
  font-weight: bold;
  font-size: 12px;
}

.expansionFieldDetails {
  margin-left: 20px;
}
