.scheduler_wrapper .scheduler_links {
  display: flex;
  flex-wrap: wrap;

  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: none;
    background: none;
    cursor: pointer;
    margin: 0 0.25rem;
  }

  p {
    margin-top: 1rem;
    margin-left: 0.5rem;
  }
}

.scheduler_wrapper p {
  line-height: 23px;
  font-size: 12px;
  color: #212b35;
  font-weight: 700;
}

.scheduler_wrapper .calendar_image {
  height: 54px;
  width: 64px;
  padding: 0 5px;
}

.scheduler_wrapper button p {
  margin: 13px 5px;
  width: 50px;
  height: 10px;
  float: right;
  text-decoration: none;
  text-align: center;
  font-size: 12px;
}

.scheduler_wrapper .schedule_time_holder {
  width: 268px;
  margin-top: 24px;
  position: relative;
  border: 2px solid #e8e8e8;
  border-radius: 4px;
}

.schedule_time_holder::after {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.schedule_time_holder::before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.schedule_time_holder::after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: white;
  border-width: 10px;
  left: 50%;
  margin-left: -10px;
}

.schedule_time_holder::before {
  border-color: rgba(51, 153, 255, 0);
  border-bottom-color: #e8e8e8;
  border-width: 13px;
  left: 50%;
  margin-left: -13px;
}

.scheduler_wrapper .schedule_time_holder p {
  clear: both;
  margin: 8px 4px 8px 0;
  line-height: 23px;
  font-size: 12px;
  padding: 10px;
  border-bottom: 2px solid #f4f6f8;
}

.scheduler_wrapper .summary {
  width: 268px;
  clear: both;
  padding-left: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  border: 2px solid #e8e8e8;
  border-radius: 4px;
  margin-bottom: 16px;
  margin-top: 8px;
}

.schedule_time_holder {
  margin-top: 40px;
}
