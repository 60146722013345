@import "styles/shared/colors.css";

.shared {
  width: 100%;
  display: flex;
  align-items: center;
}

.top {
  composes: shared;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 64px;
}

.center {
  composes: shared;
  justify-content: center;
}

.searchLoader {
  width: 100%;
  z-index: 100;
  position: absolute;
  background-color: $white;
  height: 100%;
  opacity: 0.5;
}

.tableLoader {
  width: 100%;
  z-index: 100;
  background-color: $white;
  opacity: 0.5;
  position: absolute;
  bottom: 0;
}
