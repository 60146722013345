.container {
  display: block;
  padding: 9px 8px;
  line-height: 14px;
  cursor: default;
  text-align: left;
}

.header {
  font-size: 13px;
  font-weight: 500;
  margin: 0;
}

.subtitle {
  font-size: 12px;
}
