@import 'styles/shared/colors.css';

.permissionsTable {
  border-collapse: collapse;
  font-size: 14px;
  font-weight: 500;
  width: calc(100% - 24px);
  margin-left: 12px;

  th {
    font-weight: 500;
    padding: 10px 0;
    text-align: center;
  }

  th:first-of-type {
    text-align: left;
    padding-left: 12px;
    min-width: 200px; /* matches the min-width of the user container in the first column */
    width: 250px;
  }

  tr {
    vertical-align: middle; /* This is needed for PHP, where vertical-align: baseline is set for a number of tags */
  }

  .tableHeader {
    vertical-align: middle;
    text-transform: uppercase;
    font-size: 10px;
    color: $grey600;
    letter-spacing: 0.8px;
    width: 100px; /* to match the td width */
  }

  td:first-of-type {
    width: 250px;
  }
}

.noUsersMessage {
  font-size: 15px;
}

.noUsersTip {
  font-size: 12px;
  color: $grey600;
  font-weight: 400;
}

.tooltip {
  text-transform: none;
  font-size: 11px;
  width: 25%;
  padding: 8px;
}

.permissionsTableHeader {
  color: $grey500;
  font-size: 11px;
}

.scrollableTable {
  display: block;
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
  padding: 4px 0;
}

.permissionsTableLockedHeader {
  display: block;
  width: 100%;
}

.columnHeaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
