@import "./shared/colors.css";

:global html {
  font-family: 'Roboto', sans-serif;
}

:global body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  box-sizing: border-box;
  background-color: $grey50;
  color: $textPrimary;
  line-height: 20px;
  font-size: 14px;
  overflow: hidden;
}

:global .clearfix::before,
:global .clearfix::after {
  content: "";
  display: table;
}

:global .clearfix::after {
  clear: both;
}

:global .clearfix {
  *zoom: 1;
}

:global #app,
:global #ng-app {
  display: flex;
  min-height: 100vh;
  height: 100vh;
  flex-direction: column;
}

:global input::-ms-clear {
  display: none;
}
