@import "styles/shared/breakpoints.css";

.FormUpload {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.FormUploadContainer {
  display: inline-block;
  width: 80%;
  margin-left: 10%;
  position: relative;
  z-index: 0;
}

.FormUploadDroppable {
  height: 200px;
  padding: 0;
  margin: 0;
  border: 1px dashed rgba(57, 65, 80, 0.2);
  cursor: pointer;
  text-align: center;
  background-color: #f6f9f9;
  transition: background-color 0.2s ease-out;
}

.FormUploadDroppable:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.FormUploadInput {
  display: none !important;
  visibility: hidden !important;
}

.LargeText {
  margin-top: 2rem;
  font-size: 1rem;
  color: #394150;
  font-weight: 500;
}

.SmallText {
  margin-top: 1rem;
  font-size: 1rem;
  color: #394150;
  font-weight: 500;
}

.SelectFilesButton {
  background-color: #fff;
  color: #0052b2;
  border-radius: 4px;
  padding: 1rem 1.5rem;
  border: 1px solid #0052b2;
  font-weight: 600;
  margin-top: 1rem;
  font-size: 1rem;
  cursor: pointer;
}

.FileLineContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  border-bottom: 1px solid #eee;
}

.FileLineContainer:last-child {
  border-bottom: 1px solid transparent;
  margin-bottom: 0;
}

.FilesContainer {
  margin-top: 1rem;
  width: 100%;
  overflow: auto;
  background: rgba(26, 201, 100, 0.07);
}

.FileLine {
  width: 80%;
  margin: 0 40px 0 10px;
}

.FilePreview {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.DefaultPreview {
  height: 40px;
  width: 40px;
  background-image: url("../../../assets/icons/icon60.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #eee;
  background-size: 20px;
  border-radius: 50%;
  margin: 5px;
}

.FileLineName {
  max-width: 200px;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #444;
}

.FileLineSize {
  font-size: 12px;
  color: #575757;
}

.FileLineRemove {
  font-size: 0.7rem;
  padding-right: 10px;
  color: #394150;
  cursor: pointer;
}

@media only screen and (max-width: $breakpoint__phone-portrait) {
  .FileLineName {
    max-width: 100px;
  }
}
