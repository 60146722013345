@import 'styles/shared/colors.css';

.editorContainer {
  padding: 13px 15px 17px;
}

.crossIcon {
  cursor: pointer;
  float: right;
  margin-right: -4px;
}

.name {
  font-size: 12.5px;
  margin: 6px;
}

.stageInput {
  span {
    position: static;
    font-size: 9.5px;
    line-height: 9.5px;
    margin: 14px 0;
  }

  input {
    border-color: $textPrimary;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
}

.button {
  cursor: pointer;
  width: 69px;
  height: 28px;
  border-radius: 4px;
}

.buttonSave {
  composes: button;
  color: $white;
  background: $buttonBlueBackground;
  border: 1px solid $buttonBlueBackground;
}

.buttonCancel {
  composes: button;
  background: $white;
  border: 1px solid $borderColor;
}

.stageEditWidth {
  width: 180px;
}
