.pageContainer {
  width: 100%;
  height: 100%;

  color: #051426;

  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.navigation {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;
  width: 100%;
  height: 70px;
  padding-right: 20px;
  padding-left: 20px;
}

.brandImg {
  height: 28px;
}

.helpBtn {
  padding: 10px 35px;

  transition: all 100ms ease;
  text-decoration: none;

  color: #0052b2;
  border: 1px solid #0052b2;
  border-radius: 3px;

  font-weight: 600;
}

.helpBtn:hover {
  color: #fff;
  background-color: #0052b2;
}

.errorInfoContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-color: #f1f3f4;
}

.errorImgContainer {
  width: 50%;

  text-align: center;
}

.errorImg_vertical {
  height: 100vh;
}

.errorImg_horizontal {
  width: 100%;
}

.errorImg_horizontal {
  display: none;
}

.errorDescriptionContainer {
  width: 50%;
}

.errorTitle {
  margin: 0;

  letter-spacing: -0.5px;

  color: #051426;

  font-family: Inter, sans-serif;
  font-size: 50px;
  font-weight: 600;
  line-height: 42px;
}

.errorDescription {
  margin-top: 28px;
  margin-bottom: 25px;

  font-size: 16px;
  line-height: 28px;
}

.goHomeBtn {
  display: inline-block;

  padding: 9px 35px;

  transition: all 100ms ease;
  text-decoration: none;

  color: #051426;
  border: 1px solid hsla(0, 0%, 100%, 0);
  border-radius: 3px;
  background-color: #ffa41c;

  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}

.goHomeBtn:hover {
  background-color: #df8f17;
}

@media (max-width: 991px) {
  .errorInfoContainer {
    flex-direction: column;
  }

  .errorImgContainer {
    width: 100%;
  }

  .errorImg_horizontal {
    display: block;

    width: 100%;
  }

  .errorImg_vertical {
    display: none;
  }

  .errorDescriptionContainer {
    width: 100%;

    text-align: center;
  }
}

@media (max-height: 650px) {
  .errorImg_horizontal {
    display: none;
  }
}
