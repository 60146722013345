.FormLiveViewContainer {
  padding-top: 2vh;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
  position: relative;
}

.FormLiveViewContainer * {
  box-sizing: border-box;
}

.FormLiveViewSubContainer {
  position: absolute;
  top: 6rem;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: auto;
  padding: 2rem 0 4em;
}

.FormLiveViewForm {
  display: table;
  width: auto;
  max-width: 920px;
  min-width: 720px;
  margin: 0 auto;
  background-color: #fff;
  padding: 1em 0.8em;
  box-shadow: 3px 4px 10px 1px #ccc;
  border-radius: 0.5em;
}

.FormLiveViewFieldSet {
  margin: 0;
  padding: 0;
  border: none;
}

.FormTitle {
  position: fixed;
  top: 0;
  right: 0;
  height: 4.5rem;
  width: 100%;
  display: block;
  padding: 1em 0.8em;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.6rem;
  text-align: left;
  z-index: 1;
  background-color: #fff;
  box-shadow: -285px 4px 10px 1px #ccc;
}

.FormTitleLogo {
  display: inline-block;
  width: 60px;
  max-height: 40px;
  margin: 0;
  margin-top: -0.5rem;
}

.FormTitleText {
  margin: 0;
  padding-left: 1rem;
  position: relative;
  display: inline-block;
  top: -8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1.4rem;
  line-height: 1.5rem;
  width: calc(100% - 100px);
}

.FormThankYou {
  composes: FormLiveViewForm;
  position: relative;
  text-align: center;
  padding-bottom: 5rem;
}

.mailButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: rgb(17, 147, 245);
}

.FormSideBar {
  font-family: 'Inter', sans-serif;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 280px;
  background-color: #fff;
  box-shadow: 5px 6px 9px 12px #ccc;
  min-height: 500px;
}

.FormSideBarBrandContainer {
  margin-top: 8rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem;
  overflow-wrap: break-word;
}

.FormSideBarLogo {
  display: block;
  width: 60px;
  margin: auto;
}

.FormButtonContainer {
  position: absolute;
  bottom: 6em;
  width: 100%;
  padding: 0 2.2rem;
  text-align: center;
}

.FormSubmitButton {
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  padding: 0;
  border: none;
  box-shadow: none;
  text-shadow: none;
  height: 3em;
  border-radius: 0.3em;
  text-align: center;
  display: block;
  background-color: #13365f;
  color: #fff;
  font-weight: bold;
  width: 100%;
  margin-bottom: 1em;
  line-height: 1;
}

.FormSubmitButton:disabled,
.FormNextButton:disabled {
  cursor: not-allowed;
  background-color: #20528c;
  opacity: 0.5;
}

.FormSubmitLabel {
  font-size: 12px;
}

.FormRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.FormCell {
  display: inline-block;
  flex: auto;
  padding: 0.2rem;
}

.FormErrorMessage {
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  line-height: 2.5rem;
  padding: 1rem 2rem;
}

.FormErrorIcon {
  color: red;
  width: 3rem;
  height: 3rem;
  float: left;
}

:global .form_payment_company_name {
  font-size: 14px;
}

.LoaderRow {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 1rem;
  justify-content: space-between;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100em 0;
  }
}

.LoaderCell {
  display: inline-block;
  flex: auto;
  background: #fff linear-gradient(to right, #eee 8%, #ddd 22%, #eee 40%);
  border-radius: 5px;
  margin: 0.2rem 1rem;
  height: 2.5rem;
  animation-duration: 7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
}

.PaymentModalRoot {
  bottom: revert;
  top: 40px;
  font-family: Inter, sans-serif;
}

.PaymentModalContainer {
  border-radius: 6px;
}

.PaymentModalActionButton {
  border: 1px solid #0052b2;
  color: #0052b2;
  margin: 1rem;
  padding: 0.3rem 1.5rem;
  font-family: Inter, sans-serif;
  font-weight: 500;
  min-width: 230px;
  font-size: 0.95rem;
  text-transform: none;
}

.PaymentModalActionButtonAttention {
  background-color: #0052b2;
  color: #fff;
}

.PaymentModalActionButtonAttention:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: #0052b2;
}

.PaymentModalTitle {
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  padding-top: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.PaymentModalGoToFormButton {
  background: none;
  padding: 0;
  text-decoration: underline;
  color: #000;
  border: none;
  display: inline;
  cursor: pointer;
  outline: none;
}

@media only screen and (max-width: 1300px) {
  .FormSideBar {
    bottom: 0;
    width: 100%;
    height: 120px;
    top: unset;
    min-height: 120px;
  }

  .FormLiveViewForm {
    margin-bottom: 125px;
  }

  .FormSideBarBrandContainer {
    display: none;
  }

  .FormButtonContainer {
    bottom: 3em;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    gap: 1em;
    padding: unset;
  }

  .FormSubmitButton {
    display: inline-block;
    margin-bottom: 0;
    width: 20%;
    min-width: 12rem;
  }

  .FormTitle {
    box-shadow: 0 4px 10px 1px #ccc;
  }
}

.ErrorPage {
  display: flex;
  align-items: center;
  padding: 10px;
}

.ErrorPage img {
  height: 30px;
}

.ErrorPage span {
  color: #e63f52;
  padding-left: 10px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.letterheadText {
  font-weight: normal;
  display: block;
}

.thankYouPageHeader {
  font-size: 35px;
  margin-bottom: 25px;
  line-height: 40px;
  text-align: center;
  font-weight: 700;
  width: 100%;
}

.thankYouPageMessage {
  font-size: 15px;
  line-height: 21px;
  width: 100%;
  font-weight: 500;
  text-align: center;
}

.pdfUrlLink {
  font-size: 15px;
  line-height: 21px;
  width: 100%;
  font-weight: 500;
  text-align: center;
  color: #666;
  text-decoration: none;
  border: none;
  background: none;
  cursor: pointer;
}

.pdfUrlLink:hover {
  transition: all 500ms ease;
  color: #1193f5;
  text-decoration: underline;
}

.reviewSection {
  border: 1px solid #ddd;
  margin: 0 7px 10px 7px;
  padding: 16px 20px;
}

.reviewLine {
  padding: 8px 0;
}

.reviewLineLabel {
  color: #545454;
  font-size: 16px;
  line-height: 26px;
  padding-right: 8px;
}

.reviewLineValue {
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  color: #394150;
  font-weight: 600;
}

.reviewsectionHeadingOpened {
  margin-bottom: 32px;
}

.reviewInfo {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.reviewsectionHeading {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.reviewsectionHeadingLabel {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #001824;
  display: block;
  padding-right: 7px;
}

.reviewsectionHeadingLabelContainer {
  display: flex;
}

.modalOverlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.paymentMethodsModal {
  overflow-y: auto;
  box-sizing: border-box;
  max-height: calc(100vh - 150px);
  position: absolute;
  top: 84px;
  left: 50%;
  transform: translateX(-50%);
  color: #322c2c;
  border-radius: 6px;
  background: none;
}

.paymentMethodModalHeader {
  text-align: center;
  font-size: 33px;
  font-weight: 600;
  margin: 0;
}

.paymentMethodsModalContent {
  display: flex;
  flex-direction: column;
  padding: 45px 80px 30px 80px;
  border-radius: 6px;
  background: #fff;
}

.paymentsContainer {
  display: flex;
  margin-top: 40px;
}

.paymentMethod {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  width: 210px;
  padding: 20px 24px 24px 24px;
  border: 1px solid #e0e1ec;
  border-radius: 3px;
  background: #f1f3f4;
}

.paymentAmount {
  margin: 11px 0 0 0;
  text-align: center;
  color: #6b6868;
  font-size: 12px;
  font-weight: 500;
  line-height: 23px;
}

.selectPaymentMethodButton {
  width: 100%;
  margin-top: 21px;
  padding: 10px 0;
  cursor: pointer;
  color: #2c2f32;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 1px 3px 9px rgb(0 0 0 / 8%);
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
}

.selectPaymentMethodButton:hover {
  transition: background-color 200ms linear;
  color: #fff;
  background-color: #0052b2;
}

.paymentMethodName {
  margin-top: 15px;
  color: #2c2f32;
  font-size: 19px;
  font-weight: 600;
  line-height: 23px;
}

.paymentMethod + .paymentMethod {
  margin-left: 20px;
}

.paymentIconContainer {
  height: 79px;
  display: flex;
  align-items: center;
}

.backToFormButton {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  color: white;
  font-size: 13px;
  line-height: 16px;
}

.arrowBack {
  margin-right: 8px;
}

.ACHModal {
  overflow-y: auto;
  box-sizing: border-box;
  max-width: 600px;
  height: 100%;
  max-height: calc(100vh - 150px);
  background: none;
  position: absolute;
  top: 84px;
  left: 50%;
  width: 90%;
  transform: translateX(-50%);
  color: #322c2c;
  border-radius: 6px;
}

.ACHModalContent {
  display: flex;
  border-radius: 6px;
  background: #fff;
}

.ACHInfoPanel {
  flex-shrink: 0;
  box-sizing: border-box;
  width: 165px;
  padding: 10px;
  border-radius: 6px 0 0 6px;
  background: #e2e5ea;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ACHPaymentHeader {
  margin-bottom: 5px;
  text-align: center;
  color: #2c2f32;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
}

.divider {
  padding-bottom: 5px;
  text-align: center;
  color: #7e909f;
  border-bottom: 2px solid white;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}

.ACHAmount {
  display: inline-block;
  margin: 25px 0 0;
  text-align: center;
  color: #1f53ac;
  font-size: 23px;
  font-weight: bold;
  line-height: 28px;
}

.inputFieldContainer {
  margin-bottom: 13px;
}

.inputField {
  position: relative;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #d7e0e8;
  border-radius: 5px;
  background: #fff;
  width: 169px;
}

.inputFieldLabelContainer {
  display: flex;
  justify-content: space-between;
}

.inputFieldLabel {
  margin: 0 0 5px;
  text-align: left;
  color: #081424;
  font-size: 11px;
  font-weight: bold;
  line-height: 13px;
}

.inputField input {
  transition: border linear 0.2s, box-shadow linear 0.2s;
  border-radius: 4px;
  vertical-align: middle;
  display: inline-block;
  width: 100%;
  opacity: 0.7;
  color: #081424;
  background: transparent;
  box-shadow: none;
  font-size: 16px;
  line-height: 19px;
  border: none;
  outline: none;
}

.ACHform {
  width: 100%;
  padding: 35px 45px 35px 45px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.inputIcon {
  width: 16px;
  height: 16px;
}

.submitACHBtn {
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
  cursor: pointer;
  color: #fff;
  border: 1px solid #2c508d;
  border-radius: 5px;
  background: #1f53ac;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
}

.submitACHBtn img {
  margin-right: 10px;
}

.accountTypeDropdownContainer {
  width: 100%;
  margin-bottom: 13px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #d7e0e8;
  border-radius: 5px;
  background: #fff;
}

.accountTypeDropdownContainer span {
  margin: 0 0 5px;
  text-align: left;
  color: #081424;
  font-size: 11px;
  font-weight: bold;
  line-height: 13px;
}

.accountTypeDropdownContainer select {
  outline: none;
  border: none;
  color: rgb(128, 128, 128);
  font-size: 15px;
  margin: 5px 0 5px -4px;
}

.errorMessage {
  width: 100%;
  text-align: center;
  color: #d0021b;
  font-size: 16px;
  line-height: 18px;
  padding-top: 5px;
}

.submitWithoutPaying {
  align-self: center;
  margin-top: 44px;
  cursor: pointer;
  text-decoration: underline;
  color: #2c2f32;
  border: none;
  background: none;
  font-size: 12px;
}

.visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
