.editButtonAppearance {
  composes: closedButtonAppearance from 'components/Dropdown/ClosedDropdown.css';
  composes: dropdownTrigger from 'components/AutomatedProcessBuilder/AutomatedProcessBuilder.css';
  padding: 5px 2px 5px 10px;
}

.editButtonCopyLayout {
  margin-left: 10px;
  margin-right: 15px;
}
