@import "styles/shared/colors.css";

.headerText {
  font-size: 17px;
  color: $grey800;
  font-weight: bold;
  margin: 0;
}

.apbContainerLayout {
  display: flex;
  flex-direction: column;
  height: calc(100% - 130px); /* magic number brought to you by header height + footer height */
  overflow-y: auto;
}

.apbRulesLayout {
  padding-bottom: 15px;
  width: 100%;
}

.apbBodyLayout {
  display: flex;
  flex-grow: 1;
}

.apbZeroStateLayout {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.apbZeroStateAppearance {
  text-align: center;
}

.apbHeadlineAppearance {
  color: $grey800;
  font-size: 24px;
  font-weight: 500;
}

.apbHeadlineLayout {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.apbSubheadlineAppearance {
  color: $grey650;
  font-size: 15px;
}

.apbFooterAppearance {
  border-top: 1px solid $newGrey;
  padding: 20px 16px 20px 20px; /* lifted from sidebar header */
}

.apbFooterLayout {
  align-items: center;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 24px;
}

.apbFooterButtonAppearance {
  composes: buttonReset from 'styles/shared/resets.css';
  display: flex;
  align-items: center;
}

.apbFooterButtonTextAppearance {
  font-weight: 500;
}

.apbFooterButtonTextLayout {
  margin-left: 10px;
  padding: 6px 0;
}

.saveButtonAppearance {
  composes: primaryButtonAppearance from 'styles/shared/button.css';
  padding: 0.7em 3em !important;
}

.saveButtonAppearance[disabled],
.apbFooterButtonAppearance[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.cancelButtonAppearance {
  composes: buttonReset from 'styles/shared/resets.css';
}

.cancelButtonLayout {
  margin-right: 2em;
}

.dropdownTrigger {
  border: 1px solid $blueGrey300;
  box-shadow: 0 2px 4px rgba(109, 125, 143, 0.17);
  background: $grey50;

  &:hover,
  &:focus {
    background-color: $blueGrey50;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.18);
    border-color: $blueGrey600;
  }
}
