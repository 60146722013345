@import "styles/shared/colors.css";

.columnHeader {
  margin-left: 17px;
  margin-top: 17px;
  color: $textSecondary;
  font-weight: 400;
}

.box {
  width: 200px;
  height: 100%;
  background-color: $white;
  cursor: move;
  position: absolute;
  z-index: 12;
  top: 0;
}
