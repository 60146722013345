.CheckAndTextContainer {
  display: flex;
  flex-direction: column;
  padding: 30px 30px 30px 30px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  border-color: transparent;
  background-color: white;
  width: 100%;
  margin-bottom: 25px;
  border-radius: 3px;
}
