@import 'styles/shared/colors.css';

.Container {
  position: absolute;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  height: fit-content;
  top: 110px;
  margin-left: 40px;
  z-index: 21;
}

.addToContainer {
  width: 267px;
  padding: 18px 11px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.fnrContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 478px;
  padding: 18px 11px 10px;
}

.replaceContainer {
  width: 288px;
  padding: 18px 0 10px 11px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.controlsContainer {
  display: flex;
  flex-direction: row;

  padding-top: 9px;
}

.label {
  font-size: 13px;
  font-weight: normal;
  line-height: 13px;
}

.tagsInput {
  min-height: 37px;
  width: 189px;
  margin-right: 11px;
  background: $grey50;
}

.input {
  composes: tagsInput;

  font-size: 14px;
  line-height: 18px;
}

.input fieldset {
  border: 1px solid $outlineGrey;
  border-radius: 2px;
}

.focusedInput fieldset {
  border: 1px solid $outlineBlue !important;
}

.option {
  font-size: 14px;
  line-height: 18px;
  padding-left: 10px;
}

.button {
  composes: primaryButtonAppearance from 'styles/shared/button.css';
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  border-radius: 4px;

  height: 39px;
  width: 66px;
  background: $actionBlue;
  padding: 0 15px;
}

.button:disabled {
  background: $disabledBlue;
}

.button:disabled:hover {
  cursor: default;
}

.replaceButton {
  composes: button;
  width: 76px;
  padding: 0 12px;
}

.select {
  padding: 0 0 0 10px !important;
}

.select:focus {
  background: none;
}
