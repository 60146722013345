@import "styles/shared/colors.css";

.body {
  color: $textPrimary;
  padding-top: 24px;
  text-align: left;
}

.section {
  padding-bottom: 24px;
}

.toggles {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 24px;
  margin: 0 12px;

  * {
    box-sizing: content-box; /* this is for embed, which has some global css that sets border-box * */
  }
}

.topRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.addPermissionInput {
  border: 1px solid $grey250;
  border-radius: 3px;
  width: 100%;
}

.thumbStyle {
  background-color: $white;
  height: 19px;
  margin-left: 3px;
  margin-top: 3px;
}

.thumbSwitchedStyle {
  background-color: $white;
  margin-left: 0;
}

.barStyle {
  background-color: $grey250;
  border-radius: 30px;
  height: 23px;
  margin-top: -10px;
  opacity: 1;
}

.isChecked {
  + .barStyle {
    background-color: $blue500;
    opacity: 1;
  }
}

.labelPlacementStart {
  margin-left: 0;
}

.labelStyle {
  color: $textPrimary;
  font-size: 14px;
  font-weight: 500;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
}

.closeButton:hover {
  background-color: $transparent;
}

.iconStyle {
  color: $grey600;
}
