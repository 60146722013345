.SubContainer {
  width: 100%;
}

.SubContainer > div {
  display: inline-block;
  width: 32%;
}

.SubContainer > div:first-child {
  margin-right: 2%;
}

.SubContainer > div:last-child {
  margin-left: 2%;
}
