.verified {
  color: #006705;
}

.notVerified {
  color: #ef5350;
}

.contentContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #e8e8e8;
}

.headerWrapper {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.headerLabel {
  text-transform: capitalize;
  color: #2c2f32;
  font-size: 1rem;
  font-weight: 500;
}

.contentWrapper {
  margin: 0.75rem 0;
}

.detailsRow {
  display: flex;
  padding: 0.5rem 0;
  gap: 0.5rem;
  font-size: 0.825rem;
  line-height: 0.75rem;
}

.nameText {
  color: #2c2f32;
  font-weight: 500;
}
