@import "styles/shared/colors.css";

.container {
  background-color: $white;
  width: 336px;
  height: 70px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 $lightShadow;
  display: flex;
  justify-content: center;
  align-items: center;

  > :first-child {
    opacity: 0.3;
  }
}

.stageWrapper {
  max-width: 157px;
}

.noStage {
  margin: 11px 13px;
}

.rootSnackbarContent {
  justify-content: center;
  min-width: auto;
}
