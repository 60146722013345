@import 'styles/shared/colors.css';

.richFieldsLabel {
  margin: 0;
  color: $textPrimary;
  font-size: 14px;
  width: 162px;
  font-weight: 500;
  flex-shrink: 0;
  padding: 8px 0;
  word-wrap: break-word;
  align-self: flex-start;
}

.fieldsLabel {
  margin: 0;
  color: $textSecondary;
  font-size: 12px;
  word-wrap: break-word;
}

.fieldsContent {
  margin: 0;
  color: $textPrimary;
  word-wrap: break-word;
}

.signatureCard {
  position: relative;
  padding: 12px 16px;
  border: 1px solid $grey250;
}

.richFieldWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: $textSecondary;
}

.rowFieldWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.rowFieldContent {
  color: $newPrimaryText;
  font-weight: bold;
  font-size: 12px;
}

.makeBoxPngContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 8px 0;
  border: #4f5254 1px solid;

  img {
    width: 100%;
    height: 100%;
    overflow-clip-margin: content-box;
    overflow: clip;
  }
}
