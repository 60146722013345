@import "styles/shared/colors.css";

.pageContainer {
  background-color: $white;
  width: 100%;
}

.headerContainer {
  composes: headerContainer from 'styles/shared/header.css';
  padding: 16px;
}

.contentContainer {
  padding: 64px 0;
  text-align: center;
}

.oldBrowserDoc {
  height: 200px;
  width: 200px;
}

.plea {
  color: $textPrimary;
  display: block;
  font-size: 18px;
  margin: 32px 0 16px;
}

.browserCard {
  display: inline-block;
  padding: 8px 16px 16px;
  text-decoration: none;
}

.browserName {
  color: $textPrimary;
  display: block;
}

.browserCard:hover .browserName {
  color: $orangePrimary;
}

.browserImage {
  border: none; /* border would show up in IE9 */
  height: 100px;
  width: 100px;
}

.browserCompany {
  color: $textSecondary;
}

.recommended {
  background-color: $green300;
  color: $white;
  display: block;
  font-size: 9px;
  letter-spacing: 2px;
  line-height: 9px;
  padding: 4px 6px 4px 10px;
}
