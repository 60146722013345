@import "styles/shared/colors.css";

.errorWrapper {
  position: fixed;
  right: 0;
  left: 0;
  top: 128px;
  z-index: 100;
  border-bottom: 1px solid $red600;
  background-color: $red50;
  color: $red600;
  text-align: center;
  overflow: hidden;
}

.notificationImage {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  margin-right: 8px;
}

.errorMessage {
  margin: 0;
  display: inline-block;
  vertical-align: middle;
}
