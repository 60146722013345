@import "styles/shared/colors.css";

.closedButtonAppearance {
  composes: newButton from 'styles/shared/button.css';
  overflow: hidden;

  > div[class*=optionAppearance] {
    padding: 5px 8px;
    background: transparent;
  }
}

.closedButtonChevronAppearance {
  transform: rotate(90deg);
}

.closedButtonChevronLayout {
  margin-right: 0.25em;
}

.isDisabled {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}
