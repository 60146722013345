.RatingContainer {
  display: flex;
  width: fit-content;
}

.YellowStar {
  color: #ffdc04;
}

.GreyStar {
  color: #e0dcdc;
}

.HalfStar,
.FullStar {
  display: inline-block;
  overflow: hidden;
}

.HalfStar {
  width: 17px;
}
