.addNewInputButton {
  line-height: 15px;
  -webkit-appearance: none;
  display: block;
  appearance: none;
  background-color: transparent;
  height: 55px;
  max-height: 55px;
  font-weight: 700;
  text-shadow: none;
  box-shadow: none;
  color: #212b35;
  font-size: 15px;
  border: 1px solid #c4cdd5;
  width: 208px;
  -webkit-transition: all 400ms ease;
  transition: all 100ms ease;
  font-family: inherit;
  text-align: center;
  border-radius: 3px;
  text-decoration: none;
  float: left;
  cursor: pointer;
  margin-bottom: 0;
}

.addNewInputButton:disabled {
  color: grey;
}

.removeInputButton {
  float: right;
  font-weight: 100;
  font-size: 10px;
  padding-top: 4px;
  cursor: pointer;
  appearance: none;
  border: none;
  background: transparent;
  margin-bottom: 1em;
}

.inputLabel {
  font-weight: 700 !important;
  width: auto;
}

.addNewInputButtonContainer {
  display: flex;
  flex-direction: column;
}

.addNewInputButtonContainer p {
  margin: 0;
  font-size: 10px;
}
