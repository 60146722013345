.Container {
  width: 100%;
}

.SubContainerLeft,
.SubContainer {
  width: 41%;
  display: inline-block;
}

.SubContainerMiddle {
  display: inline-block;
  width: 63px;
}

.SubContainerLeft {
  margin-right: 4%;
}
