@import 'styles/shared/colors.css';

.container {
  display: inline-block;
  cursor: default;
  border-radius: 20px;
  margin: 3px 7px 3px 0;
  vertical-align: top;
  position: relative;
  min-width: 0;

  &:not(.readonly) {
    .wide .crossmark {
      display: block;
    }

    &:hover,
    &:focus {
      cursor: pointer;

      .bubble:not(.wide) {
        cursor: pointer;
      }

      &.selected:not(.detailed) .bubble:not(.wide) {
        color: transparent !important;

        .avatar {
          opacity: 0.1;
        }

        .crossmark {
          display: block;
        }
      }

      .wide {
        background: $blueGrey50;
      }
    }
  }

  &.detailed {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    border-bottom: 1px solid;
    border-bottom-color: $blueGrey50;
    border-radius: 0;
    padding: 10px 13px;
    margin: 0;
    min-width: 200px;

    &.isHover {
      background-color: $blueGrey50;
    }

    &:not(.readonly) {
      &:hover,
      &:focus {
        background-color: $blueGrey50;

        &.selected .removeText {
          display: block;
        }
      }

      &.isMultiplePicked {
        background-color: $blue50;
      }
    }

    &.readonly {
      border-bottom: none;
    }

    .details {
      padding: 0 15px;

      p {
        color: $darkBlack;
      }
    }
  }
}

.details {
  line-height: 16px;
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  p {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    color: $blueGrey100;
  }

  & > span {
    font-size: 12px;
    color: $blueGrey400;
  }

  .hiddenEmail {
    font-style: italic;
  }
}

.bubble {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  color: $darkBlack;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid;
  border-radius: 20px;
  border-color: $blueGrey500;
  background-color: $selectionsBackground;
  width: 29px;
  height: 29px;
  cursor: default;
  position: relative;

  &.wide {
    width: auto;
    padding: 0 12px;
    font-weight: normal;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .crossmark {
      position: static;
      transform: none;
      margin: 0 -4px 0 4px;
      flex-shrink: 0;
    }
  }
}

.crossmark {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.removeText {
  background: linear-gradient(to right, transparent 0%, $blueGrey50 20%, $blueGrey50 100%);
  padding: 3px 7px 3px 20px;
  position: absolute;
  top: 5px;
  right: 8px;
  display: none;

  span {
    font-size: 11px;
    font-weight: 400;
    margin-left: 4px;
  }
}

.removeIcon {
  width: 12px;
  height: 12px;
  vertical-align: middle;
}

.avatar {
  border-radius: 50%;
  width: 100%;
}
