@import "styles/shared/colors.css";

.header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 5em;
  z-index: 1;
  box-shadow: 0 1px 4px 2px rgba(200, 200, 200, 0.6);
  background-color: #fff;
  align-content: flex-start;
  line-height: 5em;
  padding: 0;
}

.account__header {
  margin-left: auto;
  padding-right: 1.5em;
  flex-grow: 2;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.account__header a {
  color: #0052b2;
}

.button__container {
  height: 100%;
  line-height: 100%;
  padding-top: 1.6em;
  white-space: nowrap;
  flex-grow: 1;
}

.button__container button {
  border: none;
  background: transparent;
  cursor: pointer;
  margin: 0 1.3em 0 0;
  color: rgba(57, 65, 80, 0.7);
  outline: none;
  width: 32px;
  height: 32px;
}

.button__container button img {
  width: 100%;
  height: 100%;
}

.logo__name {
  padding: 0 1em;
  height: 100%;
  border-right: 1px solid #eee;
  margin-right: 2em;
  font-size: 1.1rem;
  font-weight: 400;
  font-family: 'Inter';
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex-grow: 2;
  max-width: 33vw;
}

.logo__image {
  margin-right: 1em;
  height: 37px;
  width: 37px;
  margin-top: 13px;
  float: left;
}

.container {
  display: flex;
  width: 100%;
  background-color: #edf3fa;
  flex-direction: row;
  justify-items: center;
}

.main {
  flex-grow: 6;
  padding: 5em 0 1em;
  display: block;
  max-height: 100vh;
  overflow: hidden;
}

.footer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  flex-grow: 1;
  max-width: 300px;
  max-height: 100vh;
  overflow: auto;
  box-shadow: 0 0 10px 3px rgb(0 0 0 / 15%);
  justify-content: space-between;
}

.viewer {
  margin: 0 auto;
  max-width: 820px;
  overflow: auto;
  max-height: calc(100% - 5em);
  text-align: center;
}

.PdfPage {
  max-width: 800px;
  margin: 1em auto 2em;
}

.PdfPage canvas {
  margin: auto;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.15);
}

.page__number {
  text-align: center;
  padding-top: 0.8em;
}

.footer__viewer {
  padding: 8em 0;
  margin: 0 auto;
  width: 160px;
}

.footer__viewerScroll {
  height: 70%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  width: 100%;
}

.attachments {
  border-top: 1px solid #eee;
  padding: 1em 2em;
  max-width: 300px;
  background-color: rgba(231, 233, 237, 0.3);
}

@media only screen and (min-width: 1024px) {
  .attachments__scroll {
    height: 30%;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
  }
}

.attachments ul li {
  border-bottom: 1px solid #eee;
  line-height: 1.8em;
  width: 100%;
  padding-top: 0.6em;
}

.attachments ul li a {
  display: inline-block;
  text-decoration: none;
  color: #0052b2;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.attachments ul li a img {
  margin-right: 0.5em;
}

.expiration__modal {
  text-align: center;
  padding: 3em;
  max-width: 450px;
}

.expiration__modal button {
  background: transparent;
  min-width: 207px;
  font-weight: 600;
  color: #0052b2;
  border: 1px #0052b2 solid;
  border-radius: 3px;
  padding: 0.6em 4em;
  cursor: pointer;
}

.expiration__modal button:last-child {
  background: #0052b2;
  color: #fff;
}

.errorContainer {
  margin: 100px auto 0;
  padding-top: 60px;
  background-color: white;
  height: 250px;
  width: 500px;
  border: 1px solid $grey300;
  border-radius: 5px;
}

.errorContainer span {
  color: $grey700;
}

.headerWrapper {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .viewer {
    max-height: fit-content;
  }

  .logo__name {
    max-width: 70vw;
  }

  .header {
    height: 6em;
  }

  .headerWrapper {
    height: 4em;
  }

  .account__header {
    line-height: 2em;
    border-top: 1px solid #eee;
    text-align: center;
  }

  .button__container {
    padding-top: 1.2em;
  }

  .footer {
    position: fixed;
    bottom: 0;
    height: 7vh;
    background-color: rgba(231, 233, 237, 1);
    border-top: 1px dashed #ccc;
    max-width: 100%;
    min-width: 100%;
    max-height: 20vh;
    overflow: hidden;
    transition: height 500ms ease-out, max-height 500ms ease-out;
    transition-delay: 100ms;
  }

  .footer__noAttachments {
    display: none;
  }

  .footer:focus,
  .footer:focus-within {
    height: 50vh;
    max-height: 50vh;
    overflow: auto;
  }

  .main {
    height: 79vh;
    overflow: scroll;
    padding-bottom: 1em;
    margin-bottom: 1vh;
  }

  .footer__viewer {
    display: none;
  }

  .attachments {
    padding: 1em;
    max-width: 100%;
  }

  .button__container button:not(:last-child) {
    display: none;
  }
}
