@import 'styles/shared/colors.css';

.container {
  display: inline-block;
  vertical-align: top;
}

.buttonIsOpen {
  position: relative;
}

.popoverBody {
  width: 300px;
  border-radius: 3px;
  background-color: $white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.16);
  margin-top: 5px;
  overflow: visible;

  &::before {
    content: '';
    position: absolute;
    top: -38px;
    width: 32px;
    height: 38px;
    border-radius: 15px 15px 0 0;
  }

  &.anchorRight::before {
    right: 0;
  }

  &.anchorLeft::before {
    left: 0;
  }

  &.withPeak {
    margin-top: 9px;
  }
}

.dropdownWidth {
  width: 200px;
}

.placePopoverAboveMuiModal {
  z-index: 1500; /* as the name implies, if we are hosting popover results in a modal, then the popover,
                    which is built on top of a modal, has a lower z-index than the modal */
}
