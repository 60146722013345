.fieldName {
  text-align: left;
  letter-spacing: -0.5px;

  color: #757575;

  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 19px;
}

.requiredFieldIcon {
  color: #57596e;

  font-size: 12px;
  line-height: 15px;
}

.fieldContent {
  margin-top: 9px;
}

.errorMsg {
  text-align: left;
  letter-spacing: -0.5px;

  color: #ad1100;

  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  line-height: 22px;
}
