.results {
  overflow: auto;
  max-height: 320px; /* if the whole thing is in a popover, then the body's max-height
                        takes precedence, if just the results, then this one */
  position: relative;
  border-radius: 3px;
  outline: none;
}

.body {
  display: flex;
  flex-direction: column;
  outline: none;

  & > div:last-child {
    border-bottom: 0;
  }
}

.popoverBody {
  composes: popoverBody from 'components/Popover/Popover.css';
}

.placePopoverAboveMuiModal {
  composes: placePopoverAboveMuiModal from 'components/Popover/Popover.css';
}
