.small {
  font-size: 12px;
  line-height: 16px;
}

.medium {
  font-size: 14px;
  line-height: 18px;
}

.large {
  font-size: 20px;
  line-height: 24px;
}

.largeX {
  font-size: 26px;
  line-height: 30px;
}

.largeXX {
  font-size: 48px;
  line-height: 64px;
  font-weight: 300;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}
