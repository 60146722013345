#plugin_zTdUI8_countdown {
  font-family: Tahoma, Geneva, sans-serif;
  display: inline-block;
}

.countdown_container {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.countdown_toggle_button {
  height: 55px;
  border-radius: 3px !important;
  font-family: inherit;
  font-size: 15px !important;
  font-weight: 700 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-decoration: none !important;
  width: 25% !important;
  cursor: pointer;
}

.AlignRight {
  justify-content: flex-end;
}

.AlignLeft {
  justify-content: flex-start;
}

.AlignCenter {
  justify-content: center;
}
