.Container {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  /*
  https://stackoverflow.com/questions/44938840/flexbox-item-with-overflowing-content-only-works-on-chrome
  */
  flex-shrink: 0;
}

.Subcontainer {
  display: flex;
  flex: 1;

  /*
  See http://stackoverflow.com/questions/28636832/firefox-overflow-y-not-working-with-nested-flexbox#answer-28639686
  for explanation of min-height
  */
  min-height: 0;
  overflow: hidden; /* This is here to prevent overflow on super wide submission tables */
  position: relative;
}

.SearchInput {
  width: 100%;
  font-size: 1.1rem;
  padding: 0.6rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #ccc;
}
