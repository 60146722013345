@import "styles/shared/colors.css";

.sidebarHeader {
  font-size: 18px;
  padding: 17px 16px;
}

.content {
  overflow: auto;
  padding-bottom: 90px;
  flex-grow: 1;
}

.createStageTooltip {
  padding: 6px 15px 6px 10px;
}

.divider {
  height: 1px;
  background-color: $grey200;
  margin-top: 15px;
}
