@import "styles/shared/colors.css";

.ruleWrapperLayout {
  margin: 15px 25px 0;
  box-shadow: 0 4px 4px rgba(109, 125, 143, 0.09);
  border: 1px solid $newGrey;
  border-radius: 3px;
}

.isDisabled {
  opacity: 0.75;

  .statusButtonLayout {
    background: $white;
    color: $grey400;
    box-shadow: 0 0 3px 1px rgba(154, 152, 152, 0.5);
  }
}

.isInvalid {
  background-color: $lightYellow;

  .statusButtonLayout {
    background: $amber600;
    color: $white;
    box-shadow: 0 0 3px 1px rgba(255, 211, 88, 0.92);
  }
}

.ruleLayout {
  position: relative;
}

.ruleContentLayout {
  display: flex;
  align-items: center;
}

.ruleHeader {
  border-bottom: 1px solid $newGrey;
  display: flex;
  justify-content: space-between;
  padding: 10px 8px 8px 15px;
}

.ruleHeaderTitle {
  font-weight: 500;
}

.deleteRuleButton {
  composes: buttonReset from 'styles/shared/resets.css';
  padding: 1px 5px;

  img {
    height: 13px;
    width: auto;
  }
}

.ruleButtonAndGraphics {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.rulePhraseLayout {
  align-items: center;
  display: flex;
  padding: 11px 15px 12px 80px;
  justify-content: space-between;

  &.rulePhraseGrey {
    background: rgba(109, 125, 143, 0.06);
  }
}

.conditionalLayout {
  width: 100%;
}

.secondaryCopyAppearance {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.185em;
  text-transform: uppercase;
  line-height: 1;
  margin-top: 1px;
  width: 50px;
}

.ruleCopyAppearance {
  composes: secondaryCopyAppearance;
}

.statusButtonAppearance {
  composes: buttonReset from 'styles/shared/resets.css';
}

.statusButtonLayout {
  margin: 0 16px 0 20px;
  padding: 5px 6px 3px;
  border-radius: 50%;
  background: $darkGreen;
  color: #fff;
  box-shadow: 0 0 8px 0 rgba(71, 170, 59, 0.8);
  height: 28px;
  width: 28px;
}

.triggerType {
  display: flex;
  align-items: center;
  padding: 2px 0;
}

.errorsLayout {
  display: flex;
  justify-content: center;
  padding: 1em;
}

.ruleMessageLayout {
  margin-left: 80px;
}

.ruleMessageAppearance {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.isRequired {
  font-weight: 500;
  color: $errorRed;
}

.placeholderAppearance {
  composes: secondaryCopyAppearance;
  width: auto;
}

.placeholderLayout {
  margin: 0 0.5em 0 0.1em;
}

.valueLayout {
  align-items: center;
  display: flex;
  max-width: 220px;
}
