.textContainer {
  padding: 0.5rem;
}

.nextButton {
  background-color: #1193f5;
  border: 0;
  border-radius: 4px;
  color: #fff;
  font-family: inherit;
  font-size: 1.1rem;
  line-height: 1.7em;
  min-width: 135px;
  text-align: center;
  display: block;
  padding: 8px 35px;
  height: 55px;
  width: auto;
  max-width: none;
  font-weight: 700;
  outline: none;
  float: right;
}

.nextButton:hover {
  background-color: #017bd6;
}

.nextButton:disabled {
  background-color: #ccc;
}

.ackContainer {
  background-color: #f4f6f8;
  padding: 1.2rem;
  border-radius: 3px;
  position: relative;
  margin-top: 3rem;
  min-height: 6.5rem;
}

.ackControlGroup {
  width: 75%;
  display: inline-block;
  position: relative;
  height: 100%;
}

.ackCheckbox {
  display: inline-block;
}

.ackLabel :global span {
  display: block;
}

.ackLabel :global span.has-checkbox {
  margin-left: 2rem;
  margin-top: -1.35rem;
}
