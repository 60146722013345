@import 'styles/shared/colors.css';

.container {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  min-width: 0;
}

.body {
  cursor: default;
  background-color: $white;
  white-space: nowrap;
  border: 1px solid $blueGrey200;
  padding: 9px 12px 8px;
  line-height: 14px;
  border-radius: 3px;
  box-shadow: 0 4px 4px rgba(109, 125, 143, 0.09);
  font-size: 1em;
  margin: 1px 0 0 8px;
  box-sizing: border-box;

  &:not(.selected) {
    cursor: pointer;

    &.isHover,
    &:hover {
      background-color: $blueGrey50;
      box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.18);
      border-color: $blueGrey600;
    }
  }

  &.selected {
    .itemName {
      max-width: calc(100% - 17px);
    }

    &:hover,
    &:focus,
    &.isHover {
      background-color: $blueGrey50;
      box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.18);
      border-color: $blueGrey600;

      .crossmark {
        cursor: pointer;
      }
    }
  }

  &.invalid {
    border: 1px solid $red500;
    background-color: $red50 !important;

    &:hover,
    &:focus,
    &.isHover {
      background-color: $red100 !important;
      border-color: $red700;
    }
  }
}

.itemName {
  display: inline-block;
  vertical-align: top;
  color: $darkBlack;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 15px;
  font-size: 1em;
  font-weight: 500;
  margin: 0;
  max-width: 100%;
}

.crossmark {
  vertical-align: middle;
  margin: 0 -8px 0 4px;
  padding: 0 4px;
  fill: $blueGrey500;
}

.inPopover {
  margin: 5px;
}

.tooltip {
  margin: 16px 0;
}
