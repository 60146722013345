@import 'styles/shared/colors.css';

.status {
  max-width: 160px;
  align-items: center;
  display: flex;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.wrapper {
  display: flex;
  align-items: baseline;
}

.amount {
  padding-right: 20px;
  color: $newPrimaryText;
}

.processed {
  color: $newGreen;
}

.pre-authorized {
  color: $newBlue;
}
