.Container {
  width: 100%;
  display: flex;
  max-width: 100%;
  position: relative;
  box-sizing: border-box;
}

.AlignLeft {
  justify-content: flex-start;
}

.AlignCenter {
  justify-content: center;
}

.AlignRight {
  justify-content: flex-end;
}

.Container img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.Container a {
  display: inline-block;
  max-width: 100%;
}
