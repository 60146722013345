@import "styles/shared/colors.css";

.formResponse {
  composes: small from "styles/shared/text.css";
  padding: 8px;
  background-color: $green500;
  color: $white;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: none;
  width: calc(100% - 16px);
}

.formResponseSuccess {
  composes: formResponse;
  display: block;
}

.formResponseFailure {
  composes: formResponse;
  display: block;
  background-color: $red500;
}
