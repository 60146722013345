@import 'styles/shared/colors.css';

.arrow {
  position: absolute;
  font-size: 6px;
  width: 6px;
  height: 6px;
  color: $blueGrey900;
  transform: rotate(45deg);

  &::before {
    content: "";
    display: block;
    border-style: solid;
  }

  &.top {
    bottom: -3px;
  }

  &.bottom {
    top: -3px;
  }

  &.right {
    left: -3px;
  }
}

.tooltipLabel {
  font-size: 14px;
  font-weight: 500;
  color: $blueGrey100;
  background-color: $blueGrey900;
  position: relative;
}

.tooltipPlacementBottom {
  margin: 8px 0;
}
