@import "styles/shared/colors.css";

/* SubmissionTable */
.columnHeader {
  composes: noselect from 'styles/shared/text.css';
  display: flex;
  align-items: center;
  cursor: move;
  color: $textSecondary;
  font-weight: 400;
  height: 54px;
  transition: background-color 0.2s ease-out;

  &:not(:empty) {
    flex: 1;
  }
}

.columnHeaderBold {
  composes: columnHeader;
  color: $black;
  font-weight: 500;
}

.sortArrow {
  opacity: 0.2;
  color: $black;
}

.rowNumberHeader {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 54px;
  border-right: 1px solid $grey250;
}

.rowNumberCell {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 54px;
  color: $textSecondary;
  font-size: 12px;
  background-color: $grey50;
  border-right: 1px solid $grey250;
}

.iconLock {
  margin-top: -1px;
  margin-right: 8px;
  background-size: contain;
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: top;
  border: 0 none;
  cursor: pointer;
  outline: none;
  opacity: 0.6;
}

.iconLock:hover {
  opacity: 1;
}

.decryptButton {
  composes: buttonReset from 'styles/shared/resets.css';
}

/**
 * Blank state
 */
.blankStateContainer {
  composes: blankStateContainer from "styles/shared/blankState.css";
}

.blankImage {
  composes: blankImage from "styles/shared/blankState.css";
}

.blankCopy {
  composes: blankCopy from "styles/shared/blankState.css";
}

.signatureStatusLosenge {
  border-radius: 14px;
  color: $white;
  font-size: 12px;
  padding: 6px 12px;
}

.signaturePendingProgress {
  border-left: 1px solid $grey250;
  color: $textSecondary;
  font-size: 11px;
  margin-left: 10px;
  padding-left: 6px;
}

.incompleteSubmissionIconLayout {
  margin-right: 7.5px;
  margin-top: -2px;
  vertical-align: middle;
}

.continueButtonAppearance {
  background-color: $seamlessDocsBlue;
  border-radius: 3px;
  display: inline-block;
  color: $white;
  font-size: 13px;
  font-weight: 500;
  padding: 5px 14px 5px 10px;
  text-decoration: none;
}

.continueButtonIconLayout {
  margin-left: 10px;
  vertical-align: middle;
}

.tooltip {
  /* !important necessary due to MUI application of classes */
  font-size: 13px !important;
  background-color: $darkBlack !important;
  padding: 10px 22px;
}

.sortFilterDropdownWrapper {
  display: flex;
}

.sortFilterButton {
  composes: buttonReset from 'styles/shared/resets.css';
  margin-left: 1px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition:
    opacity 0.3s ease-out,
    background-color 0.3s ease-out;

  &:hover {
    background-color: rgb(0 0 0 / 0.07);
  }
}

.sortFilterDropdownTrigger {
  composes: sortFilterButton;
  opacity: 0;
}

.sortFilterDropdownOpen {
  opacity: 1;
  background-color: rgb(0 0 0 / 0.07);
}

.columnHeader:hover .sortFilterDropdownTrigger {
  opacity: 1;
}

.sortFilterIcon {
  width: 16px;
  height: 16px;
}

.hamburgerIcon {
  transform: rotate(90deg);
}

.upArrowIcon {
  transform: rotate(180deg);
}

.filterIcon {
  width: 13px;
  height: 13px;
}

/* ID Verification Cell  */
.cellContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 160px;
  line-height: 0.75rem;
  letter-spacing: 0.08em;
}

.cellContainer span {
  font-size: 0.75rem;
}

.verified {
  color: #006705;
}

.notVerified {
  color: #ef5350;
}
