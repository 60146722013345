@import 'styles/shared/colors.css';

.container {
  border-top: 1px solid $blueGrey50;
  cursor: pointer;
  background: $white;
  text-align: left;
}

.heading {
  color: $blueGrey600;
  letter-spacing: 1px;
  line-height: normal;
  margin: 0;
  font-size: 9px;
  padding: 13px 13px 0;
  font-weight: 500;
}

.text {
  color: $blueGrey900;
  margin: 0;
  padding: 5px 13px 13px;
  font-size: 14px;
  font-weight: 500;
}

.container:hover {
  background: $blueGrey600;

  .heading {
    color: $blueGrey50;
  }

  .text {
    color: $white;
  }
}

.isSelected {
  background: $blueGrey600;

  .heading {
    color: $blueGrey50;
  }

  .text {
    color: $white;
  }
}
