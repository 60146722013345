.disabledOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 0.4);
  visibility: visible;
  zoom: 1;
  z-index: 999999;
}

.overlayModalContainer {
  background-color: white;
  z-index: 9999999;
  position: relative;
  max-width: 920px;
  top: 35%;
  margin: 0 auto;
  box-shadow: 1px 0 6px rgb(0 0 0 / 0.25);
  font-size: 1rem;
  text-align: center;
}

.disabledMessageReason {
  font-weight: bold;
  padding: 1.5rem;
  background-color: #f44336;
  color: white;
}
