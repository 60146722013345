@import 'styles/shared/colors.css';

.rowListItem {
  padding: 8px 0;
}

.headerIcon {
  width: 40px;
  margin-right: 20px;
}

.headerLabel {
  text-transform: capitalize;
  color: $newPrimaryText;
  font-size: 14px;
  font-weight: 500;
}

.headerWrapper {
  display: flex;
  align-items: flex-end;
  margin: 10px 0 20px;
}

.noBorderItem {
  border: none;
}

.processed {
  color: $newGreen;
}

.pre-authorized {
  color: $newBlue;
}

.statusWrapper {
  padding: 25px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status {
  max-width: 160px;
  align-items: center;
  display: flex;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-weight: bold;
}

.refundButton {
  composes: buttonReset from 'styles/shared/resets.css';
  font-size: 13px;
  line-height: 16px;
  text-decoration: underline;
  color: $textSecondary;
  cursor: pointer;
}

.boxList {
  list-style: none;
  padding-left: 0;
}

.boxListItem {
  composes: boxListItem from 'components/SubmissionDetails/SubmissionDetails.css';
}

.boxListItem:last-child {
  border: none;
}

.boxListContent {
  composes: boxListContent from 'components/SubmissionDetails/SubmissionDetails.css';
}

.boxListContent_totalValue {
  font-weight: bold;
}

.statusErrorLabel {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: $errorRed;
}

.statusErrorWrapper {
  background: $errorLightRed;
  border: 1px solid $errorRed;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px 20px;
}

.paymentButtonInactive {
  cursor: default;
}

.errorWrapper {
  background: $errorLightRed;
  border: 1px solid $errorRed;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px 20px;
  margin-top: 15px;
}

.errorLabel {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: $errorRed;
}

.errorText {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: $errorRed;
}

.errorHeader {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.expansionBtnContainer {
  background-color: #ebecef;
  text-align: center;
}

.expansionBtn {
  padding: 0;
}
