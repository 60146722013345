@import 'styles/shared/colors.css';

.bubble {
  border: 1.5px dashed $blueGrey300;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  margin: 3px 0;
  cursor: pointer;
  vertical-align: bottom;
  opacity: 0.6;

  &:hover {
    opacity: 1;
    border: 1.5px solid $blueGrey600;
    background: $blueGrey600;

    .icon {
      fill: $white;
    }
  }
}

.icon {
  width: 14px;
  height: 14px;
}
