@import "styles/shared/colors.css";
@import "styles/shared/breakpoints.css";

/* Padding needs to be 32px on desktop, 16px sides on mobile */
.modalContainer {
  padding: 16px 32px;
}

.title {
  composes: medium from 'styles/shared/text.css';
  color: $textPrimary;
}

.detailsContainer {
  margin-top: 12px;
  margin-bottom: 24px;
  padding-left: 0;
  border-top: 1px solid $grey200;
  border-right: 1px solid $grey200;
  border-left: 1px solid $grey200;
}

.detailWrapper {
  position: relative;
  padding: 12px 16px;
  border-bottom: 1px solid $grey200;
  box-sizing: border-box;
  display: table;
  table-layout: fixed;
  width: 100%;
}

.detailWrapperHalf {
  composes: detailWrapper;
  display: inline-block;
  width: 50%;
}

.left {
  border-right: 1px solid $grey200;
}

.icon {
  display: inline-block;
  vertical-align: top;
}

.detail {
  overflow: hidden;
  display: inline-block;
  padding-left: 1rem;
  max-width: calc(100% - 48px);
}

.label {
  composes: small from 'styles/shared/text.css';
  margin-bottom: 0;
  color: $textSecondary;
  display: block;
}

.content {
  composes: medium from 'styles/shared/text.css';
  composes: ellipsis from 'styles/shared/ellipsis.css';
  color: $textPrimary;
  overflow: hidden;
  display: block;
}

.paddingBottom {
  padding-bottom: 12px;
}

@media screen and (max-width: $breakpoint__phone-landscape) {
  .modalContainer {
    padding: 16px;
  }

  .detailWrapperHalf {
    width: 100%;
    display: table;
  }

  .detailsContainer {
    margin-bottom: 12px;
  }
}
