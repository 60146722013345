.sideBar {
  position: fixed;
  z-index: 504;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0;
  margin: 0;
  background-color: #fff;
  box-shadow: 0 3px 22px rgba(60, 69, 79, 0.27);
}

.headerContainer {
  display: flex;
  align-items: center;
  padding: 18px 16px 18px 20px;
  border-bottom: 1px solid #e8e8e8;
}

.toggleIcon {
  margin-left: auto;
  cursor: pointer;
  color: #424242;
  font-size: 18px;
  font-weight: 600;
}

.backdrop {
  position: fixed;
  z-index: 503;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  background-color: #000;
}

.headerIconWrapper {
  margin-right: 13px;
  line-height: 0;
}
