@import "styles/shared/colors.css";

.container {
  padding: 4px 2px;
  color: $white;

  span {
    display: block;
    font-weight: 500;
    margin: 5px 0;
  }

  .label {
    color: $grey400;
    font-weight: normal;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }
}
