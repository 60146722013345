@import "styles/shared/colors.css";

.sidebarContainer {
  width: 100%;
  height: 100%;
}

.sidebar {
  width: 64px;
  height: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
}

.expandedSidebar {
  width: 225px;
}

.sidebarButton {
  position: absolute;
  background-color: $white;
  z-index: 2;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  border: 1px solid $newGrey;
  box-sizing: border-box;
  cursor: pointer;
  line-height: 0;
  padding: 0;
}

.buttonCollapsed {
  left: 51px;
  top: 14px;
}

.buttonExpanded {
  left: 212px;
  top: 14px;
  transform: rotate(180deg);
}

.hamburgerHeader {
  width: 100%;
  height: 54px;
  border-bottom: 1px solid $newGrey;
}

.flexContainer {
  height: 100%;
  display: flex;
  align-items: center;

  p {
    font-size: 18px;
    color: #2c2f32;
    margin: 0 0 0 16px;
  }
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 98;
  background-color: rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 991px) {
  .sidebar {
    position: absolute;
    height: 100%;
    z-index: 99;
  }
}
