.Container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Container > div {
  display: inline-block;
}

.Entry_2 {
  width: 48%;
}

.Entry_3 {
  width: 31%;
}
