.FormSelect {
  font-family: Inter;
  width: 100%;
  font-size: 1em;
  border-radius: 3px;
  padding: 0.5em;
  margin-bottom: 1em;
  border: 1px #858d94 solid;
  min-height: 3em;
}

.readonly,
.FormSelect:disabled {
  cursor: not-allowed;
}
