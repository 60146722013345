@import 'styles/shared/colors.css';

.optionAppearance {
  cursor: pointer;
  display: flex;
  padding: 0.55em 0.75em;

  > img {
    margin-right: 4px;
  }

  &:hover,
  &.isFocused {
    background: $grey200;
  }

  &.isSelected {
    background: $grey300;
  }
}
