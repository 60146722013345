.OtherChoiceInput,
.ChoiceInput {
  height: 2em;
  margin-left: 0.4em;
  margin-right: 1em;
  font-size: 1em;
}

.ChoiceLabel {
  line-height: 2em;
  height: 2em;
  display: inline-block;
  font-size: 1em;
  vertical-align: top;
  margin-top: 4px;
}

.OtherChoiceInput {
  vertical-align: middle;
}

.OtherChoiceInputContainer {
  display: inline-block;
  width: 90%;
}

.OptionsContainer {
  display: grid;
}

.TwoColumns {
  grid-template-columns: 1fr 1fr;
}
