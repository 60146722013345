@import "styles/shared/colors.css";

.info {
  font-size: 12px;
  color: $grey600;
  margin: 0;
}

.modalButton {
  margin: 0 10px;
  height: 36px;
  border-radius: 0;
}

.cancelButton {
  composes: modalButton;
  background-color: $transparent;
  border: 1px solid $grey250;
}

.updateButton {
  composes: modalButton;
  color: $white;
}

.updateButtonCircular {
  color: $white;
  margin-left: 5px;
}

.updateButton:hover {
  background-color: $grey900;
}

.modalButtonLabel {
  text-transform: none;
  padding-left: 16px;
  padding-right: 16px;
}

.updateButtonLabel {
  composes: modalButtonLabel;
  color: $white;
}
