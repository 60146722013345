@import 'styles/shared/colors.css';

.container {
  display: flex;
  border-bottom: 1px solid $blueGrey50;
  height: 51px;
  flex-shrink: 0;

  .input {
    outline: 0;
    border: 1px solid transparent;
    border-radius: 4px;
    color: $darkBlack;
    padding: 10px;
    font-size: 13px;
    height: auto;
    margin: 7px;
    width: 100%;

    &:focus {
      border-color: $blueGrey100;
    }
  }
}
