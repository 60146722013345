.formResponse {
  composes: formResponse from "styles/shared/validation.css";
  white-space: pre-line; /* pre-line allows us to support multiline strings generated in js.
                            this allows us to show multiple pieces of feedback in the same component as a single line */
}

.success {
  composes: formResponseSuccess from "styles/shared/validation.css";
  white-space: pre-line;
}

.failure {
  composes: formResponseFailure from "styles/shared/validation.css";
  white-space: pre-line;
}
