.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 20px;
}

.archiveTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  text-decoration: none;
  margin-top: 15px;
}

.expanded {
  justify-content: flex-start;
}
