.item {
  cursor: pointer;
  display: inline-block;
  line-height: 17px;
  margin: 0 15px 15px 0;
}

.dot {
  vertical-align: top;
}
