@import 'styles/shared/colors.css';

.FieldWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: baseline;
}

.Label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.Input {
  width: 534px;
  height: 37px;
  background: #fff;
  border: 1px solid #94a4ad;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgb(0 0 0 / 0.15);
  border-radius: 4px;
  padding: 10px;
}

.ButtonWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.ErrorWrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  color: $errorRed;
}
