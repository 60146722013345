@import 'styles/shared/colors.css';

.body {
  padding: 11px 13px;
  display: flex;
  align-items: center;
  font-size: 14px;

  &:not(.inSnackbar) {
    cursor: pointer;
  }

  &:not(.inSnackbar) {
    &:hover,
    &:focus {
      background: $blueGrey50;
    }
  }
}

.name {
  margin-left: 11px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dot {
  flex-shrink: 0;
}

.focused:not(.inSidebar) {
  background: $blueGrey50;
}

.inSidebar {
  height: 32px;
  padding: 0 0 0 17px;

  &.focused {
    font-weight: 500;
  }

  .name {
    margin-left: 14px;
  }

  &.showDetails {
    &:hover,
    &:focus {
      .editIcon,
      .binIcon {
        display: block;

        &:not(:hover) {
          opacity: 0.55;
        }
      }
    }
  }
}

.count {
  font-weight: normal;
  font-size: 11px;
  padding: 0 8px;
  margin-right: auto;
  line-height: 13px;
}

.editIcon {
  display: none;
  padding: 10px 8px 10px 0;
}

.binIcon {
  display: none;
  margin-right: 16px;
}

.isEditing {
  background: $grey50;

  svg,
  .name,
  .count,
  .binIcon {
    opacity: 0.3;
  }

  .editIcon,
  .binIcon {
    display: block;
  }
}

.selectedName {
  font-size: 18px;
  font-weight: 500;
  margin-left: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 150px);
}
