.sendRegEmailBtnContainer {
  margin: 15px 60px 0;
}

.submitBtn {
  width: 100%;
  padding: 15px;

  cursor: pointer;
  letter-spacing: -0.25px;

  color: #051426;
  border: none;
  border-radius: 4px;
  background: #ffa41d;

  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: 21px;
}

.submitBtnInvalid {
  opacity: 0.5;
}

.backToLogin {
  margin-top: 30px;

  font-weight: 500;
}

.sendRegEmailTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.signUpForm {
  margin-top: 28px;
}
