.qgs_input_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 1.565rem;
  position: relative;
}

.qgs_input_container label {
  color: #212b35;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin: 0 0 0.5rem 0;
  transition: color 0.35s;
}

.qgs_input_container input[type="text"] {
  height: 3.125rem;
  border-radius: 3px;
  border: 1px solid #000;
  font-size: 0.875rem;
  transition: border 0.35s;
  outline: none;
  width: 100%;
}

.qgs_input_container input[type="text"]:focus {
  border: 2px solid #ccc;
}

.qgs_auto_complete_container {
  width: 50%;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  border: 2px solid #91858580;
  border-radius: 0.313rem;
  z-index: 10;
  padding: 0.625rem 0.313rem;
  margin-top: 0.5rem;
  box-sizing: border-box;
}

.qgs_auto_complete_container button {
  height: 1.625rem;
  width: 100%;
  border: none;
  cursor: pointer;
  background: none;
  text-align: left;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
}

.qgs_auto_complete_container button:hover {
  background-color: #eee;
}

.qgs_loading {
  width: 100%;
  height: auto;
  background: none;
  padding: 1rem 0;
  font-size: 0.875rem;
  font-weight: 400;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
