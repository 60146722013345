@import "styles/shared/colors.css";

.stageName {
  font-weight: 500;
  color: $white !important;
  margin-left: 9px;
}

.tooltip {
  padding: 6px 15px 6px 10px;
}
