.modal {
  width: 100%;
}

.close {
  position: absolute;
  top: 1px;
  right: 1px;

  color: #2f383e;
}

.paymentIconContainer {
  display: flex;
  justify-content: center;

  margin-top: 30px;
}

.paymentLabel {
  margin: 18px 0 0;

  text-align: center;

  color: #2c2f32;

  font-size: 33px;
  font-weight: bold;
  line-height: 40px;
}

.formContainer {
  display: flex;
  flex-direction: column;

  max-width: 340px;
  margin: 20px auto 0;
}

.formInputs {
  display: flex;
  flex-direction: column;
}

.labelContainer {
  display: flex;

  margin-bottom: 18px;
}

.inputsContainer {
  display: flex;
}

.rowOptionContainer {
  flex-shrink: 0;

  width: 64%;
  margin-right: 16px;
}

.formOptionLabel {
  margin: 0;

  color: #91959b;

  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.formOptionLabel_dropdown {
  flex-shrink: 0;

  width: 64%;
}

.formOptionLabel_totalValue {
  flex-grow: 1;

  text-align: right;
}

.dropDownInput {
  box-sizing: border-box;
  height: 38px;
  padding: 11px 16px;

  color: #2c2f32;

  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.dropDownIcon {
  right: 5px;
}

.inputValueContainer {
  width: 100%;
}

.textField {
  box-sizing: border-box;
}

.valueInput {
  box-sizing: border-box;
  height: 38px;
  padding: 11px 2px;

  color: #2c2f32;

  font-size: 14px;
}

.valueInput_money {
  padding-left: 0;
}

.valueInput_percent {
  padding-left: 14px;
}

.inputAdornment p {
  color: #2c2f32;

  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}

.inputError {
  margin: 8px 0 0;

  text-align: center;
}

.summaryInfoContainer {
  display: flex;
  justify-content: space-between;

  margin-top: 25px;
  padding-top: 20px;

  color: #2c2f32;
  border-top: 1px solid #ced0d2;

  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
}

.btnContainer {
  margin-top: 20px;
  margin-bottom: 10px;
}

.btn {
  padding: 11px 0;

  text-transform: none;

  color: #fff;
  background-color: #0052b2;

  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
}

.btn:hover {
  background-color: #012e63;
}

@media screen and (max-width: 700px) {
  .inputsContainer {
    flex-direction: column;
  }

  .rowOptionContainer {
    width: 100%;
    margin: 0 0 20px;
  }
}
