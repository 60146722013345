.signUpContainer {
  display: flex;
  flex-direction: column;

  max-width: 500px;
}

.signUpFormContainer {
  display: flex;
  flex-direction: column;
}

.signUpFormContainerMobile {
  flex-direction: column-reverse;
}

.signUpForm {
  margin-top: 28px;
}

.loginContainer {
  margin-top: 30px;

  letter-spacing: 0.112px;

  color: #2a2a2a;

  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 17px;
}

.loginContainerMobile {
  margin-top: 0;
}

.termsContainer {
  max-width: 285px;
  margin: 23px auto;

  text-align: center;

  color: #757575;

  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 17px;
}

.termsLink {
  text-decoration: underline !important;

  color: #757575 !important;
}

.errorMsg {
  letter-spacing: -0.5px;

  color: #ad1100;

  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 22px;
}
