.Receipt {
  display: flex;
  flex-direction: column;
}

.ReceiptInput {
  appearance: none;
  -webkit-appearance: none;
  display: inline-block;
  height: 50px;
  border: 0;
  background-color: #f4f6f8;
  box-shadow: none;
  padding-left: 15px;
  font-weight: 600;
  color: #212b35;
  margin-bottom: 15px;
}
