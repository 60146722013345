@import "./colors.css";

.buttonReset {
  background: transparent;
  border: none;
  color: $textPrimary;
  cursor: pointer;
  font-family: inherit;
  font-size: 100%;
  padding: 0;
}
