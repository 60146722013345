@import "styles/shared/colors.css";

.inputLabel {
  composes: medium from "styles/shared/text.css";
  color: $textPrimary;
  display: block;
  margin-bottom: 5px;
  font-weight: 400;
}

.inputField {
  font-size: 14px;
  height: 42px;
  display: block;
  padding: 0 12px;
  margin-bottom: 10px;
  color: $textPrimary;
  background-color: $white;
  border: 1px solid $grey250;
  outline: 0;
  width: calc(100% - 26px);
  box-shadow: none;

  &::placeholder {
    color: $grey500;
  }

  &:-ms-input-placeholder {
    color: $grey500;
  }
}

.inputField:focus {
  border: 1px solid $orangePrimary;
}

.inputFieldError {
  border: 1px solid $red500;
}

.invisibleSubmit {
  /* Browsers have different requirements for enabling form submission via the return key.
   * Safari & IE require that the input[type='submit'] be "visible".
   */
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.select {
  font-size: 14px;
  color: $textPrimary;
  vertical-align: middle;
  background-image: linear-gradient($white 0, $grey150 100%);
  height: 42px !important; /* Override V1 */
  margin-bottom: 0; /* Override V1 */
  padding: 8px 12px;
  border: 1px solid $grey200;
  background-color: $white;
  width: 100%;
  -moz-appearance: none;
  border-radius: 4px;
}

.select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 $black;
}

.select:focus {
  border-color: $orangePrimary;
  outline: 0;
}
