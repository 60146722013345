.Fieldset {
  border: none;
  padding: 0;
}

.Fieldset legend {
  padding: 1em 0.3em;
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 0.03em;
}

.Fieldset label {
  font-weight: 400;
  font-size: 0.95em;
}

.Fieldset fieldset > legend {
  display: none;
}

.FormControlGroup {
  width: 100%;
  margin-bottom: 0.5em;
}

.FormLabel {
  display: inline-block;
  width: 100%;
  padding: 1em 0.3em;
  cursor: auto;
  clear: both;
  color: #212b35;
  font-weight: 600;
  letter-spacing: 0.02em;
  float: none;
}

.Required::after {
  content: " *";
  color: #ad0000;
  font-size: 21px;
}

.h1 {
  font-size: 2rem;
}

.h2 {
  font-size: 1.4rem;
}

.labelAlignRight {
  margin-left: auto;
}

.labelAlignLeft {
  margin-right: auto;
}

.AlignRight {
  text-align: right;
}

.AlignLeft {
  text-align: left;
}

.AlignCenter {
  text-align: center;
}
