@import 'styles/shared/colors.css';

.bubble {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 20px;
  padding: 4px 2px 4px 10px;
  border: 1px solid $grey400;

  &:not(.empty) {
    background: $selectionsBackground;

    &:not(.isReadOnly) {
      &:hover,
      &:focus {
        background: $blueGrey50;
      }
    }
  }

  &.empty {
    border-width: 0;
    padding: 0;
  }

  &.isReadOnly {
    padding-right: 13px;
    cursor: default;
  }
}

.dot {
  margin-right: 10px;
  flex-shrink: 0;
}

.label {
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: $darkBlack;
  line-height: 20px;
}

.remove {
  padding: 10px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  border-bottom: 1px solid $blueGrey50;

  &:hover,
  &.focused {
    background: $blueGrey50;
  }

  .icon {
    width: 14px;
    height: 14px;
    margin-right: 11px;

    & img {
      width: 100%;
    }
  }
}

.anchor {
  width: 29px;
  height: 29px;

  /* The material ui popover doesn't have such thing as offset,
     so in order to show it further down where I needed it to be
     I had to enlarge the anchor and give it a negative margin
     so it doesn't take the actual space */
  margin: -10px 0 -9px;
  display: flex;
  justify-content: center;
  align-items: center;
}
