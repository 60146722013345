@import "styles/shared/colors.css";

.saveButtonAppearance {
  composes: primaryButtonAppearance from "styles/shared/button.css";
}

.saveButtonLayout {
  margin-top: 1em;
  padding: 0.5em 3em;
}

.chevronAppearance {
  transform: rotate(180deg);
}

.emailEditorAppearance {
  padding: 20px;
  text-align: left; /* overrides legacy CSS centering */
}

.saveButtonAppearance[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.labeledRow {
  composes: triggerType from "../Rule/Rule.css";
  padding: 0 5px 20px;
}

.labeledRowLabel {
  composes: secondaryCopyAppearance from "../Rule/Rule.css";
  width: 80px;
}

input[type="text"].emailEditorSubject {
  background: $white;
  border: 1px solid $blueGrey200;
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(109, 125, 143, 0.09);
  border-radius: 3px;
  flex-grow: 1;
  padding: 10px 15px;
  font-size: 1em;
  height: auto;
  color: $black;
  margin-bottom: 0;
  line-height: 1;
}
