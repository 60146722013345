@import "styles/shared/colors.css";

.headerText {
  font-size: 14px;
  color: $textPrimary;
  font-weight: bold;
  margin: 0;
}

.headlineDetails {
  font-size: 12px;
  line-height: 17px;
  margin-left: 25px;
  color: $textSecondary;
  align-self: flex-end;
}
