.FormMultilineControlGroup {
  width: 100%;
  margin-bottom: 0.5em;
}

.FormTextareaLabel {
  display: inline-block;
  width: 100%;
  padding: 1em 0.3em;
  cursor: auto;
  clear: both;
  color: #212b35;
  font-weight: 600;
  letter-spacing: 0.02em;
  float: none;
}

.FormTextarea {
  display: inline-block;
  font-size: 1em;
  font-family: Inter;
  width: 100%;
  padding: 0.8em 1em;
  margin-bottom: 1em;
  color: #30373b;
  border-radius: 3px;
  border: 1px #858d94 solid;
  max-width: 100%;
  min-height: 8em;
  resize: none;
}

.FormTextarea:read-only {
  cursor: not-allowed;
}
