.autocomplete_input {
  width: 100%;
  min-height: 3.125rem; /* 50px */
  font-size: 0.875rem; /* 14px */
  border-radius: 3px;
  padding: 0.5rem;
  border: 1px solid #858d94;
}

.autocomplete_entries {
  display: flex;
  flex-direction: column;
  border: 1px solid #c3c3c3;
  background-color: #fff;
  text-align: left;
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  z-index: 50;
  width: 100%;
  transition: opacity 350ms ease-in-out;
}

.autocomplete_item {
  background-color: #fff;
  color: #444;
  display: flex;
  white-space: nowrap;
  font-size: 0.875rem; /* 14px */
  line-height: 1.5rem;
  border: none;
}

.autocomplete_entries button:hover {
  background-color: #444;
  color: #fff;
}

.hideEntries {
  opacity: 0;
  visibility: hidden;
}

.showEntries {
  opacity: 1;
  visibility: visible;
}
