@import "styles/shared/colors.css";

.chevron {
  opacity: 0.5;

  &.isOpen {
    transform: rotate(90deg);
  }
}

.quickFilterAppearance {
  align-items: center;
  box-sizing: content-box;
  border: 1px solid transparent;
  display: flex;
  composes: buttonReset from 'styles/shared/resets.css';
  height: 21px;
  padding-top: 5px;
  padding-bottom: 5px;

  &:hover {
    background-color: $grey200;
    border: 1px solid $grey600;
    color: $newPrimaryText;
    font-weight: 500;

    .quickFilterIconAppearance {
      opacity: 1;
    }
  }

  &.isSelected {
    color: $newPrimaryText;
    font-weight: 500;

    .quickFilterIconAppearance {
      opacity: 1;
    }
  }
}

.quickFilterLayout {
  padding-left: 48px;
  width: 200px;

  &:hover {
    margin-left: -8px;
    padding-left: 56px; /* 48px from above plus make up for the negative margin */
    width: 242px;
  }
}

.quickFilterHeaderLayout {
  width: 280px;

  &:hover {
    margin-left: -8px;
    padding-left: 8px;
    width: 290px;
  }
}

.quickFiltersLayout {
  margin-left: 8px; /* hover state of quick filter is negative this value */
  margin-top: 16px;
}

.quickFilterIconAppearance {
  height: 20px;
  opacity: 0.4;
  width: 20px;
}

.quickFilterIconLayout {
  margin-left: 8px;
  margin-right: 10px;
}
