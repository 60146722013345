@import 'styles/shared/colors.css';

.highlight {
  background-color: $orange500;
  border-radius: 4px;
  color: $white;
  font-size: 13px;
  padding: 2px 4px;
  position: relative;
  top: -1px;
}
