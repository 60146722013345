@import "styles/shared/colors.css";
@import "styles/shared/breakpoints.css";

.pageWrapper {
  background: white;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  * {
    box-sizing: border-box;
  }
}

.container {
  align-self: center;
  margin: 0 auto;
  background-color: $white;
  display: flex;
  flex-direction: column;
  width: 520px;
}

.header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  min-height: 96px;
}

.companyLogoWrapper {
  display: flex;
  flex-direction: column;
  height: 96px;
  justify-content: center;
  margin-right: 24px;
  min-height: 96px;
  min-width: 96px;
  width: 96px;
}

.companyLogo {
  display: block;
  max-height: 96px;
  width: 96px;
}

.organizationName {
  align-self: center;

  h6 {
    margin: 0;
    color: $grey650;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    span {
      font-weight: 500;
    }
  }

  h3 {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0.25px;
    line-height: 36px;
    margin: 0;
  }
}

.subtext {
  color: $textSecondary;
  font-size: 14px;
  margin-bottom: 20px;
}

.nameFields {
  display: flex;
  justify-content: space-between;

  input,
  select {
    width: 50%;

    &:first-child {
      margin-right: 16px;
    }
  }
}

.field {
  composes: inputField from "styles/shared/input.css";
  width: 100%;
  height: 50px;
  margin-bottom: 16px;
}

.disabledEmailField {
  composes: field;
  background: $grey200;
  cursor: not-allowed;
}

.registerButtonContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
}

.resendInviteButtonWrapper {
  display: flex;
  justify-content: flex-end;
}

.responseBoxWrapper {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
}

.invisibleSubmit {
  composes: invisibleSubmit from 'styles/shared/input.css';
}

@media screen and (max-width: $breakpoint__small-xx) {
  .container {
    margin: 0;
    max-width: 100%;
    min-width: 0;
    padding: 20px;
  }
}
