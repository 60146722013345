@import "styles/shared/colors.css";
@import "styles/shared/breakpoints.css";

.headerContainer {
  composes: headerContainer from 'styles/shared/header.css';
}

.logoContainer {
  composes: buttonReset from 'styles/shared/resets.css';
  display: flex;
  float: left;
  padding: 1rem;
}

.logoMobile {
  display: none;
}

.logoDesktop {
  display: block;
}

.navigation {
  display: flex;
  margin-left: auto;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
    }
  }
}

/*
 * Containers within the header must be exactly 64px in height
 */
.rightContainer {
  padding: 16px;
  height: 32px;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.button {
  composes: medium from "styles/shared/text.css";
  display: inline-block;
  color: $textPrimary;
  text-decoration: none;
  padding: 1.44rem 1rem;
}

.button:hover {
  color: $grey900;
  background-color: $grey100;
  box-shadow: none;
}

@media screen and (max-width: $breakpoint__desktop) {
  .logoMobile {
    display: block;
  }

  .logoDesktop {
    display: none;
  }

  .navigation {
    display: none;
  }
}

@media screen and (max-width: $breakpoint__phone-portrait) {
  .rightContainer {
    padding: 0;
  }
}
