@import 'styles/shared/colors.css';

.formContainer {
  padding-top: 16px;
}

.formLabel {
  composes: inputLabel from 'styles/shared/input.css';
}

.formInput {
  composes: inputField from 'styles/shared/input.css';
}

.formInputContainer {
  margin: 0 16px;
}

.formInputError {
  composes: formInput;
  composes: inputFieldError from 'styles/shared/input.css';
}

.exportAll {
  cursor: pointer;
  color: $seamlessDocsBlue;
  text-decoration: underline;
}

.folderNameInput {
  composes: inputField from 'styles/shared/input.css';
}

.folderNameLabel {
  composes: inputLabel from 'styles/shared/input.css';
  padding-top: 10px;
  padding-bottom: 6px;
}

.modalContainer {
  padding: 0 8px;
}

.headerContainerStyle {
  padding: 0 16px 0 0;
}

.bodyStyle {
  padding: 0 16px 16px;
  overflow-y: auto;
}

.paper {
  border-radius: 0;
}

.modalContent {
  padding: 0 16px;
}

.checkboxColumn {
  display: flex;
  flex-direction: column;
}

.hiddenInput {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  top: 0;
}

.selectWrapper {
  margin: 5px 5px 10px;
}
