@import 'styles/shared/colors.css';

.permissionsRow {
  background-color: $grey50;

  td {
    vertical-align: middle; /* set explicitly for legacy */
    width: 100px;
  }
}

.tableRow {
  border-top: 1px solid $grey250;
  border-bottom: 1px solid $grey250;
}

.permissionUserGroup {
  display: flex;
  align-items: center;
  width: 250px;
}

.permissionUserName {
  width: 250px;
}

.permissionRowDelete {
  padding: 5px;
  height: auto;
  width: auto;

  &:hover {
    background-color: $transparent;
  }
}

.checkbox {
  margin-left: calc(50% - 18px);

  input[type="checkbox"] { /* legacy bootstrap makes checkboxes weirdly small, reducing the clickable area */
    width: 100%;
  }
}

@media (max-width: 440px) {
  .permissionUserGroup {
    max-width: 195px;
  }

  .permissionUserName {
    width: 90%;
  }
}
