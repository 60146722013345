@import 'styles/shared/colors.css';

.button:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px 0;
}

.button:disabled {
  box-shadow: none;
}

.flatBordered {
  border-radius: 0;
}

.flatBordered:hover {
  color: $white !important;
  background-color: $orangePrimary !important;
}

.blueFlatBordered:hover {
  transition: box-shadow 0.1s !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.labelPadding {
  padding-left: 16px;
  padding-right: 16px;
}

.label {
  composes: labelPadding;
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
}

.ellipsifiedLabel {
  composes: label;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ripple {
  background-color: $orange700;
}

.flatBorderedLabel {
  composes: labelPadding;
  font-weight: 400;
  padding: 0;
  text-transform: none;
}
