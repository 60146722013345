.surveyMatrixContainer table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.surveyMatrixContainer td {
  min-height: 250px;
}

.surveyMatrixContainer input[type="radio"] {
  height: 18px;
  width: 18px;
}

.col {
  font-weight: normal;
  padding-bottom: 23px;
}

.th {
  font-size: 12px;
  line-height: 18px;
  padding: 10px 1px;
}

.select {
  text-align: center;
}
