@import "styles/shared/colors.css";
@import "styles/shared/breakpoints.css";

.companyLogo {
  display: block;
  max-height: 96px;
  width: 96px;
}

.nameFields {
  display: flex;
  justify-content: space-between;

  label {
    width: 50%;

    &:first-child {
      margin-right: 16px;
    }
  }
}

.registrationForm {
  composes: form from "components/AuthLayout/styles.css";
  width: 510px;

  h2 {
    font-size: 25px;
  }

  p {
    margin-top: 0;
    font-size: 13px;
  }
}

.registrationSubmission {
  composes: registrationForm;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 400px;

  p {
    text-align: center;
    font-size: 16px;
  }
}

.emailPasswordFields {
  display: flex;
  justify-content: space-between;

  label {
    width: calc(33% - 8px);
  }
}

.disabledEmailField {
  composes: field from "components/AuthLayout/styles.css";
  background: $grey200;
  cursor: not-allowed;
}

.thankYouHeader {
  font-size: 35px;
}

.thankYouText {
  font-size: 19px;

  a {
    text-decoration: none;
    font-weight: 700;
    color: $buttonBlueBackground;
  }
}

@media screen and (max-width: $breakpoint__small-xx) {
  .container {
    margin: 0;
    max-width: 100%;
    min-width: 0;
    padding: 20px;
  }
}

.banner {
  font-size: 11px;
}
