@import 'styles/shared/colors.css';

.Text {
  font-size: 14px;
  color: $grey850;
  padding: 2px 0 14px;
}

.FieldWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: baseline;
}

.Label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.FieldWrapper .CustomSelectStyle {
  width: 534px;
  border: 1px solid #94a4ad;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background: #fff;
}

.FieldWrapper .CustomSelectStyle input {
  max-width: 520px;
}

.FieldWrapper .customResultsClassName {
  width: 534px;
}

.Input {
  width: 534px;
  height: 37px;
  background: #fff;
  border: 1px solid #94a4ad;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 10px;
}

.SelectWrapper {
  z-index: 2;
}

.Error {
  border-color: #ad0000 !important;
  box-shadow: 0 0 1px 1px #ad0000 !important;
}

.note {
  display: flex;
  margin-right: auto;
  align-items: center;
}
