@import url(https://fonts.googleapis.com/css?family=Meddon);

.Button {
  height: 40px;
  box-shadow: 0 0 0 1px #858d94;
  border: none;
  background: white;
  padding: 1em 3em;
  background-color: white;
  border-radius: 0.3em;
  font-weight: bold;
}

.Canvas {
  box-shadow: 0 0 0 1px #858d94;
  border-radius: 0.3em;
  background:
    repeating-linear-gradient(
      45deg,
      transparent,
      transparent 10px,
      rgba(200, 200, 200, 0.1) 10px,
      rgba(200, 200, 200, 0.1) 11px
    ),
    repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 10px,
      rgba(200, 200, 200, 0.1) 10px,
      rgba(200, 200, 200, 0.1) 11px
    );
  margin-bottom: 5px;
}

.SignatureWrapper {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  margin-bottom: 1.5em;
}
