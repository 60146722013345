.EmoticonsWrapper {
  display: flex;
  margin-top: 20px;
}

.Emoticon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Emoticon label {
  font-size: 50px;
  cursor: pointer;
}

.Emoticon input {
  height: 18px;
  width: 18px;
  cursor: pointer;
  margin-top: 25px;
}

.EmoticonsWrapper legend {
  margin-bottom: 40px;
  padding: 0;
}
