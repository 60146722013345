@import 'styles/shared/colors.css';

.bubble {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-radius: 20px;
  font-size: 12px;
  border-color: $blueGrey600;
  background-color: $selectionsBackground;
  color: $darkBlack;
  margin: 3px 7px 3px 0;
  cursor: pointer;
  width: 29px;
  height: 29px;
  vertical-align: bottom;
}
