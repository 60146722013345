@import "styles/shared/colors.css";

/*
 * Form Details
 */
.container {
  overflow-y: scroll;
}

/*
 * Activity
 */
.activityContainer {
  padding: 16px 0;
}

.activityRow {
  display: block;
  padding: 16px 16px 16px 8px;
  border-bottom: 1px solid $grey100;
  background-color: $white;
}

.activityIcon {
  display: inline-block;
  margin: 0 16px 0 8px;
  vertical-align: top;
}

.activityData {
  display: inline-block;
}

.activity {
  display: block;
  color: $textPrimary;
}

.activityName {
  font-weight: 500;
}

.activityDate {
  display: block;
  font-size: 12px;
  color: $textSecondary;
}

/*
  Tabs
*/

.tabsRoot {
  border-bottom: 1px solid $veryLightGrey;
  min-height: 56px;
}

.tabIndicator {
  display: none;
}

.tabSelected {
  .tabLabel {
    color: $buttonBlueBackground;
  }
}

.tab {
  width: 50%;
  background-color: $white;
  color: $textPrimary;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  opacity: 1;
}

.tabIcon {
  display: flex;
  margin-right: 10px;
}

.tabLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
