.FormInput {
  font-family: Inter, sans-serif;
  display: inline-block;
  width: 100%;
  padding: 0.8em 1em;
  margin-bottom: 1em;
  color: #30373b;
  height: 3em;
  border-radius: 3px;
  border: 1px #858d94 solid;
  font-size: 1em;
}
