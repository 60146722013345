@import "styles/shared/colors.css";

.errorLayout {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.errorCopy {
  line-height: 38px;
  text-align: center;
  width: 66%;
  letter-spacing: 1px;
}

.block {
  color: $red900;
  font-size: 48px;
}

.errorMessageGroup {
  margin-top: 18%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  > p {
    color: $darkBlue;
    margin-bottom: 5%;
  }
}

.goBack {
  background-color: $veryDarkBlue;
  color: $white;
  text-transform: none;
  padding: 12px 16px;
  width: 130px;

  &:hover {
    background-color: $seamlessDocsBlue;
  }
}

.goBackLabel {
  margin-left: 10%;
}

.lobbyLink {
  margin-bottom: 3%;
}
