.UIWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.textContainer {
  margin: 0.5rem;
}

.passwordFieldContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
}

.FormInput {
  font-family: Inter, sans-serif;
  padding: 0.8em 1rem;
  margin: 1rem;
  color: #30373b;
  height: 3em;
  border-radius: 3px;
  border: 1px #858d94 solid;
  font-size: 1em;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}

.errorContainer {
  color: red;
}
