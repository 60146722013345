.BrushWidthContainer > div {
  display: flex;
  align-items: center;
  height: 25px;
  margin-top: 15px;
}

.BrushWidthContainer input {
  -webkit-appearance: none;
  height: 1px;
  width: 150px;
  border-top: 1px solid #ddd;
  margin-right: 15px;
}

.BrushWidthContainer input::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #eee;
  cursor: pointer;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  border: 1px solid #c4cdd5;
}

.Brush {
  display: inline-block;
}

.Hue {
  height: 10px;
  position: relative;
  margin-bottom: 10px;
}

.Saturation {
  height: 100px;
  position: relative;
}

.EditableInput {
  height: 34px;
  padding-left: 10px;
}

.Swatch {
  display: inline-block;
  vertical-align: middle;
  width: 33px;
  height: 33px;
  margin-right: 12px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
}

.ColorPickerInput {
  display: flex;
}

.ColorPickerInput input {
  font-family: inherit;
  font-size: 14px;
  -webkit-transition: all 150ms;
  transition: all 150ms;
  appearance: none;
  -webkit-appearance: none;
  width: 150px;
  display: inline-block;
  height: 50px;
  -webkit-box-shadow: 0 0 0 1px #858d94;
  box-shadow: 0 0 0 1px #858d94;
  border: 0;
  border-radius: 3px;
  color: #30373b;
  background-color: #fff;
  padding: 6px 8px 6px 12px;
  cursor: pointer;
}

.ColorPickerModal {
  width: 250px;
  background: rgb(255, 255, 255);
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 12px;
  border-radius: 4px;
  position: absolute;
  padding: 5px;
  margin-top: 10px;
  cursor: pointer;
}

.BrushColorContainer {
  margin-right: 50px;
}

.BrushColorContainer > div {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.Controls {
  display: flex;
  margin: 15px 0;
}

.ClearCanvasBtn {
  background-color: transparent;
  height: 55px;
  max-height: 55px;
  font-weight: 700;
  text-shadow: none;
  box-shadow: none;
  color: #212b35;
  border: 1px solid #c4cdd5;
  width: 208px;
  border-radius: 3px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  margin-bottom: 0;
}
