@import "styles/shared/colors.css";

.notificationContainer {
  padding-top: 32px;

  /* padding-bottom is 16px on the Modal.jsx. The goal is to have it at 32px (16 + 16) */
  padding-bottom: 16px;
}

.statusImageWrapper {
  width: 100%;
}

.statusImage {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 16px;
}

.title {
  composes: medium from 'styles/shared/text.css';
  line-height: 24px;
  text-align: center;
  color: $textPrimary;
  margin-bottom: 4px;
}

.bodyText {
  composes: medium from 'styles/shared/text.css';
  text-align: center;
  color: $textSecondary;
  font-size: 13px;
  line-height: 20px;
}

.progressBar {
  display: flex;
  justify-content: center;
}
