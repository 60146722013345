.body {
  max-height: 97vh;
  overflow-x: auto;
  outline: none;
  border-radius: 3px;
}

.dropdownWidth {
  width: 200px;
}
