@import "styles/shared/colors.css";

.blankStateContainer {
  order: 2;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 64px;
  border: 1px solid $grey250;
  border-top: 0;
  box-sizing: border-box;
}

.blankImage {
  max-width: 120px;
}

.blankCopy {
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: $darkBlack;
  text-align: center;
}
