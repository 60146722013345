@import 'styles/shared/colors.css';

.stageInput {
  background: $white;
  border: 1px solid $blueGrey200;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 6px;
  width: 100%;
  font-size: 13px;
  outline: 0;

  &:focus {
    box-shadow: 0 0 8px $grey200;
  }
}

.message {
  display: block;
  position: absolute;
  margin-top: 2px;
  font-size: 12px;
  color: $blueGrey900;
}

.error {
  .stageInput {
    border: 1px solid $errorRed;
  }

  .message {
    color: $errorRed;
  }
}
