@import "styles/shared/colors.css";
@import "styles/shared/breakpoints.css";

.pageWrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  color: $newPrimaryText;
}

.innerPageWrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.mainContent {
  background-color: $grey100;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  * {
    box-sizing: border-box;
  }
}

.formNameHeader {
  display: flex;
  justify-content: space-between;
  height: 105px;
  border-bottom: 1px solid $grey300;

  .formName {
    font-size: 20px;
  }
}

.formInfoLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5%;
  justify-content: center;
}

.formInfoRight {
  display: flex;
  align-items: center;
  margin-right: 5%;

  .secure {
    font-weight: 700;
    margin-left: 10px;
  }
}

.sidebar {
  background-color: $white;
  width: 20%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 2%;
  border-left: 1px solid $grey400;
  letter-spacing: 0.5px;
  z-index: 2;
}

.sidebarButton {
  border: 1px solid $blueGrey400;
  background-color: $white;
}

.sidebarButton:hover {
  background-color: $blueGrey400;
}

.sidebarButtonLabel {
  text-transform: none;
  font-size: 12.5px;
  text-decoration: none;
}

.formSwitchLink {
  text-decoration: none;
}

.accountInfo {
  margin-top: 35%;

  .companyName {
    font-size: 20px;
  }

  .logo {
    width: 55px;
  }

  .contactInfo {
    font-size: 13px;
  }
}

.switchAuthForm {
  font-weight: 700;
  margin-bottom: 25%;

  p {
    font-size: 16px;
    line-height: 19px;
  }
}

.container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 400px;
  max-width: 650px;
}

.form {
  background-color: $white;
  border-radius: 10px;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  min-height: 96px;
  width: 425px;
  align-items: center;
}

.headline {
  text-align: center;

  h1 {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 1px;
  }
}

.companyLogoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 24px;
  height: 50px;
  width: 50px;
}

.companyLogo {
  display: block;
  max-height: 50px;
  width: 50px;
}

.organizationName {
  align-self: center;

  h6 {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    span {
      font-weight: 500;
    }
  }

  h3 {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0.25px;
    line-height: 36px;
    margin: 0;
  }
}

.subtext {
  font-size: 14px;
  margin-bottom: 20px;
}

.field {
  composes: inputField from "styles/shared/input.css";
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 10px;
  background-color: $grey50;
  border-radius: 5px;
  border: 1px solid $grey250;
  font-size: 13px;

  &:focus {
    background-color: $white;
    border: 1px solid $grey600;
    box-shadow: 2px 3px 10px $grey250;
  }
}

.label {
  font-weight: 600;
}

@media screen and (max-width: $breakpoint__small-xx) {
  .container {
    margin: 0;
    max-width: 100%;
    min-width: 0;
    padding: 20px;
  }
}

.errorMessageWrapper {
  display: flex;
  width: 100%;
  margin-bottom: 3%;
  align-items: center;
  height: 40px;
  background-color: $errorRed;
  color: $white;
  font-size: 11.5px;
  border-radius: 3px;
}

.blueCircle {
  position: absolute;
  top: 0;
  right: 49px;
  height: 165px;
  width: 309px;
  background-image: url(images/blue-circle.svg);
}

.orangeCircle {
  position: absolute;
  top: 0;
  right: 0;
  height: 220px;
  width: 225px;
  background-image: url(images/orange-circle.svg);
}

.termsOfService {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 50px 61px;
  text-decoration: none;
  color: $black;
  font-size: 13px;
  line-height: 18px;
}
