.ngSettingsContainer {
  padding: 0.625rem; /* 10px */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10%;
}

.ngSettingsContent {
  padding: 0.625rem; /* 10px */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50rem; /* 800px */
  gap: 1.5rem; /* 24px */
}

.ngSettingsTitle {
  color: #081325;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 1.25rem; /* 20px */
  font-weight: 600;
  line-height: normal;
}

.ngSettingsDescription {
  color: #081325;
  font-family: Roboto, sans-serif;
  font-size: 0.875rem; /* 14px */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ngSettingsToggleContainer {
  display: flex;
  align-items: center;
  align-self: stretch;
}

.ngSettingsToggleText {
  color: #081325;
  font-family: Roboto, sans-serif;
  font-size: 0.9375rem; /* 15px */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ngSettingsButtonContainer {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 1.25rem; /* 1.25rem already in rem */
}

.ngSettingsSaveButton {
  width: 9.5rem; /* 152px */
  height: 2.5rem; /* 40px */
  border-radius: 0.1875rem; /* 3px */
  border: 1px solid #0052b2;
  background-color: #0052b2;
  color: #fff;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 0.9375rem; /* 15px */
  font-weight: 400;
  cursor: pointer;
}

.ngSettingsSaveButton:hover {
  background-color: #003f8c;
}

/* ==================Confirmation Modal Styles======================== */

.confirmationModalOverlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #303a40;
}

.confirmationModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 9.375rem); /* 150px */
}

.confirmationModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 37.5rem; /* 600px */
  height: 21.375rem; /* 342px */
  border-radius: 0.375rem; /* 6px */
  background: #fff;
  gap: 2rem;
}

.confirmationModalTitleContainer {
  display: flex;
  width: 37.5rem; /* 600px */
  height: 1.9375rem; /* 31px */
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.confirmationModalTitle {
  color: #322c2c;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 2.0625rem; /* 33px */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.020625rem; /* -0.33px */
}

.confirmationModalTextContainer {
  width: 26.8125rem; /* 429px */
  height: 4.9375rem; /* 79px */
  flex-shrink: 0;
}

.confirmationModalText {
  color: #2c2f32;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 0.875rem; /* 14px */
  font-style: normal;
  font-weight: 400;
  line-height: 1.2105rem; /* 19.367px */
  letter-spacing: -0.00875rem; /* -0.14px */
}

.confirmationModalButtonsContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 2.5rem; /* 40px */
  gap: 1rem; /* 16px */
}

.cancelButton {
  width: 13rem; /* 208px */
  height: 2.5rem; /* 40px */
  flex-shrink: 0;
  border-radius: 0.1875rem; /* 3px */
  border: 1px solid #0052b2;
  color: #0052b2;
  box-shadow: 0.0625rem 0.1875rem 0.1875rem 0 rgba(0, 0, 0, 0.08); /* 1px 3px 3px */ /* Light Button Shadow */
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 0.9375rem; /* 15px */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #fff;
  cursor: pointer;
}

.cancelButton:hover {
  background-color: #eaeaea;
}

.continueButton {
  width: 13rem; /* 208px */
  height: 2.5rem; /* 40px */
  flex-shrink: 0;
  border-radius: 0.1875rem; /* 3px */
  background: #0052b2;
  border: 1px solid #0052b2;
  color: #fff;
  box-shadow: 0.0625rem 0.1875rem 0.1875rem 0 rgba(0, 0, 0, 0.08); /* 1px 3px 3px */ /* Light Button Shadow */
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 0.9375rem; /* 15px */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.continueButton:hover {
  background-color: #003f8c;
}
