@import 'styles/shared/colors.css';

@value toolbarHeight: 64px;
@value searchIconPaddingLeft: 8px;
@value searchIconPaddingRight: 10px;
@value searchIconWidth: 24px;

.inactiveLink {
  pointer-events: none;
  cursor: default;
}

.titleIcon {
  border: none;
  color: $white;
  min-width: toolbarHeight;
  height: toolbarHeight;
  line-height: 48px;
  border-radius: 0;
}

.titleIconLabel {
  /*
    Material-UI IconButton labels display with flex by default, occasionally leaving a difficult-to-fix positioning issue
    display: inline is a low-impact fix
  */
  display: inline;
}

.titleIconWrap {
  height: toolbarHeight;
  background-color: $orange500;
  margin-right: 16px;
  position: relative;
  z-index: 1;
}

.searchGroup:hover {
  background-color: $grey900;
}

.searchGroup {
  composes: medium from 'styles/shared/text.css';
  background-color: $grey800;
  color: $white;
  height: toolbarHeight;
  margin-left: auto;
}

.searchGroupOpen {
  composes: searchGroup;
  background-color: $grey900;
}

.searchIconContainer {
  align-self: center;
  display: inherit;
}

.searchIcon {
  padding: 0 searchIconPaddingRight 0 searchIconPaddingLeft;
  width: searchIconWidth;
  color: $white;
  opacity: 0.5;
}

.searchInputContainer {
  background-color: inherit;
  color: inherit;
  font-size: inherit;
  overflow: hidden;
  position: relative;
  height: toolbarHeight;
}

.searchInput {
  background-color: transparent;
  border: 0;
  box-sizing: border-box;
  color: inherit;
  font-family: 'Roboto', sans-serif;
  font-size: inherit;
  height: toolbarHeight;
  outline-width: 0;
  padding-left: 0;
  position: absolute;
  width: 100%;
}

.searchInput[disabled] {
  /* This is a hack to support browsers (Firefox) which hide events on disabled elements */
  pointer-events: none;
}

.searchInputHighlights {
  color: transparent;
  position: absolute;
  line-height: toolbarHeight;
}

/* Toolbar buttons style fixes */

.toolbarButton {
  opacity: 0.5;
  border-radius: 0;
}

.toolbarButton:hover {
  opacity: 1;
  background-color: $grey900;
}

.toolbarButtonIcon {
  color: $white;
}

.toolbarButtonTooltip {
  font-size: 14px;
  padding: 1.5px 8px;
  border-radius: 2px;
}

.toolbarButtonTooltipPlacement {
  margin-bottom: 2px;
}

.menuItemIcon {
  color: $grey600;
  margin-right: 17%;
}

.mobileMenuPopover {
  width: 224px;
}
