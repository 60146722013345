@import 'styles/shared/colors.css';

.container {
  display: inline-flex;
  max-width: 100%;
}

.wrapItems {
  flex-wrap: wrap;
  justify-content: flex-end;
}

.peak {
  position: absolute;
  transform: rotate(45deg);
  height: 10px;
  width: 10px;
  background-color: $white;
  top: -5px;
  left: 30px;
}
