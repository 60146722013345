.ErrorContainer input:not(:focus):not([type=checkbox]):not([type=radio]),
.ErrorContainer select:not(:focus),
.ErrorContainer textarea:not(:focus) {
  box-shadow: 0 0 2px 2px #ad0000;
}

.Error {
  position: absolute;
  width: max-content;
  padding: 10px;
  background-color: #ad0000;
  color: white;
  border-radius: 3px;
  z-index: 1;
}

.Error::before {
  content: "";
  position: absolute;
  top: -7px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ad0000;
}

.hide {
  opacity: 0;
}
