@import 'styles/shared/colors.css';

.linkInputContainer {
  background-color: $grey50;
  border-color: $grey250;
  border-style: solid;
  border-width: 1px 1px 0;
  padding: 8px 8px 4px;
}

.linkURLDoneTextContainer {
  color: $green300;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
}

.linkURLDoneTextContainer:hover {
  color: $green500;
}

.linkURLTooltip {
  font-size: 14px;
  height: 24px;
}

.colorPickerWrapper {
  composes: linkInputContainer;
  padding: 8px;
  padding-right: 0;
  overflow-x: scroll;
}

.colorPickerBody {
  width: 100%;
  flex-direction: row;
  display: flex;
  min-width: 500px;
}

/* V1 Override */
.controlsWrapper svg {
  box-sizing: border-box;
}

/* V1 Override */

.label {
  fill: currentColor;
  font-size: 13px;
  height: 20px;
  width: 24px;
  padding: 0 4px;
  text-transform: none;
  vertical-align: middle;
  background-color: $grey150;
}

.labelInactive {
  composes: label;
  color: $grey600;

  g {
    fill: $grey600;
  }
}

.labelActive {
  composes: label;
  color: $orangePrimary;

  g {
    fill: $orangePrimary;
  }
}

.iconInactive {
  color: $grey600;
  fill: currentColor;
  height: 24px;
  width: 24px;

  g {
    fill: $grey600;
  }
}

.iconActive {
  color: $orangePrimary;
  fill: currentColor;
  height: 24px;
  width: 24px;

  g {
    fill: $orangePrimary;
  }
}

.button {
  font-size: 13px;
  height: 20px;
  line-height: 20px;
  min-width: 0;
  padding: 0;
  width: 24px;
}

.tooltip {
  font-size: 14px;
  height: 100%;
}
