.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.info_box_container {
  background: #fff;
  border: solid 1px #f0f0f0;
  border-bottom: none;
  border-radius: 0.5rem;
  padding: 0.5rem;
  height: auto;
  width: 100%;
  min-width: 1px;
  max-width: 648px;
  max-height: 223px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.info_box_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 0.5rem;
}

.info_box_content span {
  font-size: 0.75rem;
  font-family: 'Roboto', sans-serif;
}

.info_box_container::before {
  content: '×';
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-align: center;
  color: #333;
  opacity: 0.5;
  height: 1rem;
}

.info_box_container:hover::before {
  opacity: 1;
}
