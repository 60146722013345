.detailContainer {
  padding-top: 16px;
}

.detailsIcon {
  display: inline-block;
  margin: 14px 16px 0 8px;
  vertical-align: top;
}

.detailsData {
  display: inline-block;
}
