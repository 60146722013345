@import 'styles/shared/colors.css';

.container {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  min-width: 0;
}

.body {
  cursor: default;
  background-color: $selectionsBackground;
  white-space: nowrap;
  border: 1px solid $blueGrey500;
  padding: 7.5px 10px;
  line-height: 14px;
  border-radius: 4px;
  font-size: 12px;
  margin: 3px 8px 3px 0;
  box-sizing: border-box;

  &:not(.selected) {
    &:not(.readonly) {
      cursor: pointer;

      &.isHover,
      &:hover {
        background-color: $darkBlue;
        box-shadow: 1px 1px 2px rgba(54, 72, 95, 0.3);

        .tagName {
          color: $white;
        }
      }

      &.isMultiplePicked {
        background-color: $blue50;
      }
    }
  }

  &.selected:not(.readonly) {
    .tagName {
      /* The 17px comes from 10px from the padding between the cross and the paragraph plus 7px from the width of the cross */
      max-width: calc(100% - 17px);
    }

    &:hover,
    &:focus,
    &.isHover {
      background-color: $blueGrey50;

      .crossmark {
        cursor: pointer;
      }
    }
  }
}

.tagName {
  display: inline-block;
  vertical-align: top;
  color: $darkBlack;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 14px;
  font-size: 12px;
  margin: 0;
}

.crossmark {
  vertical-align: top;
  margin: 0 -4px 0 6px;
  padding: 0 4px;
  fill: $blueGrey500;
}

.inPopover {
  margin: 5px;
}

.tooltip {
  margin: 16px 0;
}
