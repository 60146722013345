@import 'styles/shared/colors.css';

.createStageContainer {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 0 0 17px;

  &:not(.isEditing) {
    cursor: pointer;

    &:hover,
    &:focus:not(.inDropdown) {
      background: $blueGrey50;
    }
  }
}

.icon {
  height: 13px;
  width: 13px;
  margin-right: 13px;
  cursor: pointer;
}

.focused {
  background: $blueGrey50;
}

.inDropdown {
  border-top: 1px solid $blueGrey50;
  padding: 5px 5px 5px 13px;
  outline: none;

  &.isEditing {
    padding-bottom: 23px;
  }

  .icon {
    margin-right: 10px;
  }
}
