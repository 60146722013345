@import 'styles/shared/colors.css';

.Select {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 190px;
  min-height: 38px;
  height: fit-content;
  background: $grey50;
  border: 1px solid $outlineGrey;
  border-radius: 2px;
  position: relative;
}

.Select:focus-within {
  border: 1px solid $outlineBlue;
}

.resultsListContainer {
  padding: 5px;
  background: $grey50;
  text-align: left;
}

.Input {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  border: 0;
  height: 27px;
  width: 5px;
  max-width: 170px;
  min-width: 5px;
  background: transparent;
}

.Input:focus {
  outline: none;
}

.Results {
  position: fixed;
  width: 280px;
  background-color: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  margin-top: 5px;
}

.Selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SelectionRemove {
  cursor: pointer;
}

.Selections {
  width: 100%;
  height: fit-content;
  padding: 0 5px;
}

.MultipleSelections {
  margin-top: 4px;
}

.SelectedOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: left;
  padding: 3px 5px;
  background: #f9f9fb;
  border: 1px solid #667c8a;
  border-radius: 3px;
  margin: 0 4px 4px 0;
}

.SelectedOption span {
  display: block;
  max-width: 150px;
  word-wrap: break-word;
  white-space: normal;
}

.SelectedOption .SelectionRemove {
  margin-left: 5px;
}

.hidden {
  display: none;
}

.placeholder {
  position: absolute;
  left: 4px;
  top: 4px;
}

.Error {
  text-decoration: #ad0000 underline dashed 2px;
}
