@import 'styles/shared/colors.css';

.container {
  align-items: center;
  display: flex;
  padding: 10px;

  &:hover,
  &.isHover {
    background-color: $grey50;
    cursor: pointer;
  }
}

.displayNameContainer {
  color: $darkBlack;
  font-size: 14px;
  font-weight: 500;
  padding: 0 15px;
}

.roleIcon {
  width: 29px;
  height: 29px;
}
