@import "./colors.css";

.button {
  composes: medium from "./text.css";
  cursor: pointer;
  text-decoration: none;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  user-select: none;
  background-color: $grey500;
  color: $white;
  transition: all 0.4s ease 0s;
  outline: 0;
}

.newButton {
  background: $grey50;
  border: 1px solid $grey400;
  border-radius: 4px;
  color: $textPrimary;
  cursor: pointer;
  font-family: inherit;
  font-size: 100%;
  padding: 0;
  display: flex;
  align-items: center;

  &:hover {
    background: $blueGrey50;
  }
}

.primaryButtonAppearance {
  background-color: $seamlessDocsBlue;
  border-radius: 3px;
  border: none;
  color: $white;
  cursor: pointer;
  font-family: inherit;
  font-size: 100%;
  font-weight: 500;
  padding: 0;
}
