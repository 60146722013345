.StyledText ul {
  list-style-type: disc;
  padding-inline-start: 40px;
}

.StyledText blockquote {
  padding: 0 0 0 15px;
  margin: 0 0 20px;
  border-left: 5px solid #eee;
}
