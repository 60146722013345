@import 'styles/shared/colors.css';

.colorPickerContainer {
  margin-right: -15px;
  width: 160px;
}

.colorList {
  margin: 0;
  padding: 0;
}
