:root {
  --primary-color: #0c57cd;
  --primary-border-color: #063b99;
  --primary-text-color: #fff;
  --background-overlay: rgba(0, 0, 0, 0.8);
  --font-family: 'Inter', sans-serif;
  --font-color: #394150;
  --color-neutral-darkest: #121212;
  --font-size-title: 1rem; /* 16px */
  --font-size-subtitle: 0.85rem; /* 13px */
  --font-size-button: 0.85rem; /* 13px */
  --font-weight-bold: 600;
  --font-weight-regular: 400;
  --button-height: 3rem;
  --button-width: 10rem;
  --button-radius: 6px;
  --padding-small: 0.5rem;
  --padding-medium: 2rem;
  --loader-bg-color: #fff;
  --loader-color: #0c57cd;
}

/* ==================== Container Styles ==================== */
.container {
  display: flex;
}

/* ==================== Text Wrapper ==================== */
.textWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 var(--padding-small);
  color: var(--font-color);
  font-family: var(--font-family), sans-serif;
  line-height: 1.25rem;
}

.title {
  font-size: var(--font-size-title);
  font-weight: var(--font-weight-bold);
}

.subTitle {
  font-size: var(--font-size-subtitle);
  font-weight: var(--font-weight-regular);
}

/* ==================== Button Wrapper ==================== */
.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

/* ==================== Verify Button ==================== */
.verifyButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--padding-small);
  cursor: pointer;
  font-family: var(--font-family), sans-serif;
  padding: 0 var(--padding-small);
  height: var(--button-height);
  border-radius: var(--button-radius);
  background-color: var(--primary-color);
  border: 1px solid var(--primary-border-color);
  color: var(--primary-text-color);
  font-weight: bold;
  width: var(--button-width);
  line-height: 1;
  box-shadow: 0 2px 1px rgb(255 255 255 / 0.24) inset, 0 -2px 1px rgb(18 18 18 / 0.12) inset;
}

.verifyButton img {
  padding: var(--padding-small);
}

/* ==================== Overlay ==================== */
.overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--background-overlay);
}

/* ==================== Persona Container ==================== */
.personaContainer {
  box-sizing: border-box;
  max-height: calc(100vh - 150px);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.personaWrapper > iframe {
  min-height: 650px;
  min-width: 400px;
}

/* ==================== Back to Form Button ==================== */
.backToFormButton {
  display: flex;
  align-items: center;
  margin-bottom: 0.65rem;
  margin-left: var(--padding-small);
  cursor: pointer;
  color: var(--primary-text-color);
  font-size: var(--font-size-button);
  line-height: 1rem;
  background: none;
  border: none;
}

.backToFormButton > img {
  margin-right: var(--padding-small);
}

/* ==================== Loader Component ==================== */
.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 256px;
  height: 144px;
  border-radius: 10px;
  gap: var(--padding-small);
  background-color: var(--loader-bg-color);
}

.loader {
  border: 8px solid var(--loader-bg-color);
  border-top: 8px solid var(--loader-color);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loaderText {
  color: var(--color-neutral-darkest);
  font-family: "Source Sans 3", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.18px;
}

.errorText {
  color: #ad0000;
  font-size: 18px;
  font-weight: 600;
}

/* ==================== Data Fetch Error Container ==================== */
.fetchErrorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 256px;
  padding: 24px 20px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0 12px 0 #1212123d;
}

.fetchErrorContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.fetchErrorHeader {
  font-family: "Source Sans 3", sans-serif;
  font-size: 20px;
  font-weight: 720;
  line-height: 28px;
}

.fetchErrorText {
  font-family: "Source Sans 3", sans-serif;
  font-size: 13px;
  font-weight: 480;
  line-height: 20px;
  color: #6b7280;
}

.fetchErrorButtonContainer {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 24px;
  gap: 8px;
}

.fetchErrorButton {
  width: 104px;
  height: 32px;
  font-size: "Source Sans 3", sans-serif;
  font-size: 15px;
  line-height: 20px;
  border-radius: 6px;
  padding: 6px 12px 6px 12px;
}

.fetchErrorRetryButton {
  color: #fff;
  border: 1px solid #063b99;
  background-color: #0c57cd;
  box-shadow: 0 -2px 1px 0 #1212121f inset;
  box-shadow: 0 2px 1px 0 #ffffff3d inset;
}

.fetchErrorCancelButton {
  border: 1px solid #d5d7db;
  background-color: #fff;
}

/* ==================== Verified Data ==================== */
.dataContainer {
  display: grid;
  grid: auto-flow / 1fr 1fr;
  gap: 16px 96px;
  margin-top: 16px;
}

.dataHeader {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}

.dataItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dataItemName {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.dataItemValue {
  font-size: 14px;
  font-weight: 720;
  line-height: 20px;
}
