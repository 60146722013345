.addThisEvent_drop {
  width: auto;
  display: inline-block;
  position: relative;
  z-index: 999998;
  cursor: pointer;
  background: #f7f7f7;
  font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  color: #333;
  font-size: 12px;
  padding: 2px 12px 2px 35px;
  border: 1px solid #cfcfcf;
  border-radius: 2px;
}

.addThisEvent_drop .date {
  width: 60px;
  height: 60px;
  float: left;
  position: relative;
  margin-left: -33px;
  margin-top: 2px;
}

.addThisEvent_drop .date .mon {
  display: block;
  text-align: center;
  padding: 4px 0 0 0;
  font-size: 16px;
  color: #bf5549;
  font-weight: bold;
  line-height: 110%;
  text-transform: uppercase;
}

.addThisEvent_drop .date .day {
  display: block;
  text-align: center;
  padding: 0 0 8px 0;
  font-size: 30px;
  font-weight: bold;
  color: #333;
  line-height: 100%;
}

.addThisEvent_drop .date .bdr1 {
  width: 1px;
  height: 50px;
  background: #eaeaea;
  position: absolute;
  z-index: 100;
  top: 5px;
  right: -3px;
}

.addThisEvent_drop .date .bdr2 {
  width: 1px;
  height: 50px;
  background: #fff;
  position: absolute;
  z-index: 100;
  top: 5px;
  right: -4px;
}

.addThisEvent_drop .desc {
  width: 170px;
  height: 60px;
  float: left;
  position: relative;
}

.addThisEvent_drop .desc .hed {
  height: 15px;
  margin-top: 6px;
  margin-left: 17px;
  font-size: 14px;
  line-height: 110%;
  color: #333;
  text-transform: capitalize;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.addThisEvent_drop .desc span {
  height: 50px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  margin: 0;
  text-align: left;
  padding: 7px 0 0 18px;
  font-family: arial, sans-serif;
  font-size: 12px;
  font-weight: 100;
  color: #666;
  line-height: 110%;
}

.dropdown_arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  float: right;
  margin-top: 30px;
}

.addThisEvent_dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 4rem;
  left: 0;
  width: 256px;
  z-index: 99999;
  background: #fff;
  text-align: left;
  margin-top: 0.75rem;
  border-top: 1px solid #c8c8c8;
  border-right: 1px solid #bebebe;
  border-bottom: 1px solid #a8a8a8;
  border-left: 1px solid #bebebe;
  box-shadow: 1px 3px 6px rgb(0, 0, 0, 0.15);
}

.addThisEvent_dropdown button {
  cursor: pointer;
  width: 254px;
  font-size: 14px;
  color: #212b35;
  padding: 12px 10px 10px 12px;
  border: none;
  background: #fff;
  text-align: left;
  text-decoration: underline;
}

.addThisEvent_dropdown button:hover {
  text-decoration: none;
  background: #f4f4f4;
}
