@import "styles/shared/colors.css";

/*
 * NOTE: Styles for fixed-data-table (Table library used in this component) is located at styles/fixed-data-table.css. That style sheet is shared with this component and Submission Table
 */

.headerCell {
  display: flex;
  align-items: center;
  color: $textSecondary;
  composes: medium from "styles/shared/text.css";
  font-weight: 400;
  font-size: 13px;
}

.submissionsBlock {
  padding: 4px 8px;
  border-radius: 3px;
  background-color: $transparent;
  transition: opacity 200ms ease-out;
  color: $buttonBlueBackground;
  text-decoration: none;

  .submissionsLabelWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .submissionsLabel {
    font-size: 13px;
    font-weight: 500;
    color: $transparent;
  }

  &.showSubmissionButton {
    background-color: $buttonBlueBackground;

    .submissionsLabel {
      color: $white;
    }
  }
}

.submissionsBlockDesktop {
  display: flex;
  width: 90%;
  max-width: 90px;
}

.submissionsBlockZero {
  color: $buttonBlueBackground;
  padding: 4px;
  border-radius: 4px;
  background-color: $white;
}

.newSubmissions {
  color: $buttonBlueBackground;
  background-color: $white;
  font-size: 13px;
  padding: 4px;
  border-radius: 4px;
}

.iconSubmissions {
  display: inline-block;
  padding: 8px;
  border-radius: 20px;
  background-color: $white;
  line-height: 0;
}

.previewForm {
  font-weight: 400;
  padding: 16px;
  background-color: $darkWhite;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 2px;
  box-shadow: 0 2px 6px 0 $lightShadow;
  display: inline-block;

  img {
    float: left;
    margin-right: 8px;
  }
}

.tablePaymentIcon {
  width: 18px;
  padding-left: 15px;
}

/**
 * Loader + Blank state
 */
.loaderContainer {
  composes: blankStateContainer from "styles/shared/blankState.css";
}

.loaderImage {
  composes: blankImage from "styles/shared/blankState.css";
}

.loaderCopy {
  composes: blankCopy from "styles/shared/blankState.css";
}
