@import 'styles/shared/colors.css';

:global .public-DraftEditor-content figure {
  margin: 0;
}

:global .public-DraftEditor-content figure img {
  /* We raise the image because wrapped text will often take precedence when the image is left-aligned */
  z-index: 1;
}

.blockquote {
  border-left: 5px solid $grey250;
  color: $grey700;
  font-family: 'Hoefler Text', 'Baskerville Old Face', Garamond, 'Times New Roman', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

:global .public-DraftStyleDefault-pre {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  background-color: $grey250;
  border-radius: 0;
  border: none;
  font-family: monaco, Consolas, 'Lucida Console', monospace;
  padding: 20px;
}

:global .public-DraftStyleDefault-ltr {
  text-align: inherit !important;
}

:global .fp__overlay {
  z-index: 2000 !important;
}

.codeBlock {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  background-color: $grey250;
  border-radius: 0;
  border: none;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  word-break: break-all;
  word-wrap: break-word;
}

.controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.alignRight {
  text-align: right;
}

.link {
  color: $blue900;
}
