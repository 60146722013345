@import "./shared/colors.css";

/**
 * These are the styles for the `fixed-data-table` library we use.
 * They need to be applied globally, otherwise we won't be able
 * to update the styles.
 *
 * The `fixed-data-table` component *MUST* be a child of
 * an element with the class `fixedDataTable`. This
 * reduces the risk of these styles spilling over.
 * Even though the library's naming convention is pretty strict
 *
 * Original File:
 * fixed-data-table-base.css
 */

:global .fixedDataTable .fixedDataTableCellGroupLayout_cellGroup {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

:global .fixedDataTable .fixedDataTableCellGroupLayout_cellGroup > .public_fixedDataTableCell_main {
  white-space: normal;
}

:global .fixedDataTable .fixedDataTableCellGroupLayout_cellGroupWrapper {
  position: absolute;
  top: 0;
}

:global .fixedDataTable .fixedDataTableCellLayout_main {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  position: absolute;
  white-space: normal;
}

:global .fixedDataTable .fixedDataTableCellLayout_alignRight {
  text-align: right;
}

:global .fixedDataTable .fixedDataTableCellLayout_alignCenter {
  text-align: center;
}

:global .fixedDataTable .fixedDataTableCellLayout_columnResizerContainer {
  position: absolute;
  right: 0;
  width: 6px;
  z-index: 1;
}

:global .fixedDataTable .fixedDataTableCellLayout_columnResizerContainer:hover {
  cursor: ew-resize;
}

:global .fixedDataTable .fixedDataTableCellLayout_columnResizerContainer:hover .fixedDataTableCellLayout_columnResizerKnob {
  visibility: visible;
}

:global .fixedDataTable .fixedDataTableCellLayout_columnResizerKnob {
  position: absolute;
  right: 0;
  visibility: hidden;
  width: 4px;
}

/**
 * ColumnResizer
 */

:global .fixedDataTable .fixedDataTableColumnResizerLineLayout_mouseArea {
  cursor: ew-resize;
  position: absolute;
  right: -5px;
  width: 12px;
}

:global .fixedDataTable .fixedDataTableColumnResizerLineLayout_main {
  border-right-style: solid;
  border-right-width: 1px;
  box-sizing: border-box;
  position: absolute;
  z-index: 10;
}

:global .fixedDataTable body[dir="rtl"] .fixedDataTableColumnResizerLineLayout_main {
  /* the resizer line is in the wrong position in RTL with no easy fix.
   * Disabling is more useful than displaying it.
   * #167 (github) should look into this and come up with a permanent fix.
   */
  display: none !important;
}

:global .fixedDataTable .fixedDataTableColumnResizerLineLayout_hiddenElem {
  display: none !important;
}

/**
 * fixedDataTableLayout
 */

:global .fixedDataTable .fixedDataTableLayout_main {
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

:global .fixedDataTable .fixedDataTableLayout_header,
:global .fixedDataTable .fixedDataTableLayout_hasBottomBorder {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

:global .fixedDataTable .fixedDataTableLayout_footer .public_fixedDataTableCell_main {
  border-top-style: solid;
  border-top-width: 1px;
}

:global .fixedDataTable .fixedDataTableLayout_topShadow,
:global .fixedDataTable .fixedDataTableLayout_bottomShadow {
  height: 4px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1;
}

:global .fixedDataTable .fixedDataTableLayout_bottomShadow {
  margin-top: -4px;
}

:global .fixedDataTable .fixedDataTableLayout_rowsContainer {
  overflow: hidden;
  position: relative;
}

:global .fixedDataTable .fixedDataTableLayout_horizontalScrollbar {
  bottom: 0;
  position: absolute;
}

:global .fixedDataTable .fixedDataTableRowLayout_main {
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  top: 0;
}

:global .fixedDataTable .fixedDataTableRowLayout_body {
  left: 0;
  position: absolute;
  top: 0;
}

:global .fixedDataTable .fixedDataTableRowLayout_fixedColumnsDivider {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-left-style: solid;
  border-left-width: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
}

:global .fixedDataTable .fixedDataTableRowLayout_columnsShadow {
  width: 4px;
}

:global .fixedDataTable .fixedDataTableRowLayout_rowWrapper {
  position: absolute;
  top: 0;
}

:global .fixedDataTable .ScrollbarLayout_main {
  box-sizing: border-box;
  outline: none;
  overflow: hidden;
  position: absolute;
  transition-duration: 250ms;
  transition-timing-function: ease;
  user-select: none;
}

:global .fixedDataTable .ScrollbarLayout_mainVertical {
  bottom: 0;
  right: 0;
  top: 0;
  transition-property: background-color width;
  width: 15px;
}

:global .fixedDataTable .ScrollbarLayout_mainVertical.public_Scrollbar_mainActive,
:global .fixedDataTable .ScrollbarLayout_mainVertical:hover {
  width: 17px;
}

:global .fixedDataTable .ScrollbarLayout_mainHorizontal {
  bottom: 0;
  height: 15px;
  left: 0;
  transition-property: background-color height;
}

/* Touching the scroll-track directly makes the scroll-track bolder */
:global .fixedDataTable .ScrollbarLayout_mainHorizontal.public_Scrollbar_mainActive,
:global .fixedDataTable .ScrollbarLayout_mainHorizontal:hover {
  height: 17px;
}

:global .fixedDataTable .ScrollbarLayout_face {
  left: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}

/**
 * This selector renders the "nub" of the scrollface. The nub must
 * be rendered as pseudo-element so that it won't receive any UI events then
 * we can get the correct `event.offsetX` and `event.offsetY` from the
 * scrollface element while dragging it.
 */
:global .fixedDataTable .ScrollbarLayout_face::after {
  border-radius: 6px;
  content: '';
  display: block;
  position: absolute;
  transition: background-color 250ms ease;
}

:global .fixedDataTable .ScrollbarLayout_faceHorizontal {
  bottom: 0;
  left: 0;
  top: 0;
}

:global .fixedDataTable .ScrollbarLayout_faceHorizontal::after {
  bottom: 4px;
  left: 0;
  top: 4px;
  width: 100%;
}

:global .fixedDataTable .ScrollbarLayout_faceVertical {
  left: 0;
  right: 0;
  top: 0;
}

:global .fixedDataTable .ScrollbarLayout_faceVertical::after {
  height: 100%;
  left: 4px;
  right: 4px;
  top: 0;
}

:global .fixedDataTable .fixedDataTableCellGroupLayout_cellGroup {
  backface-visibility: hidden;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

:global .fixedDataTable .public_fixedDataTable_header {
  cursor: default;
  color: $headerColor;
  font-size: 14px;
  font-weight: 400;
}

:global .fixedDataTable .public_fixedDataTable_bodyRow:hover {
  cursor: pointer;
  background-color: $rowHoverColor;
}

:global .fixedDataTable .fixedDataTableCellGroupLayout_cellGroup > .public_fixedDataTableCell_main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  white-space: normal;
}

:global .fixedDataTable .fixedDataTableCellGroupLayout_cellGroupWrapper {
  position: absolute;
  top: 0;
}

:global .fixedDataTable .fixedDataTableCellLayout_main {
  border-width: 0;
  box-sizing: border-box;
  display: block;
  position: absolute;
  font-size: 14px;
}

:global .fixedDataTable .fixedDataTableCellLayout_wrap1 {
  width: 100%;
  height: 100%;
}

:global .fixedDataTable .fixedDataTableCellLayout_wrap2 {
  width: 100%;
  height: 100%;
}

:global .fixedDataTable .fixedDataTableCellLayout_wrap3 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

:global .fixedDataTable .fixedDataTableCellLayout_columnResizerContainer {
  position: absolute;
  right: 0;
  width: 6px;
  z-index: 1;
}

:global .fixedDataTable .fixedDataTableCellLayout_columnResizerContainer:hover {
  cursor: ew-resize;
}

:global .fixedDataTable .fixedDataTableCellLayout_columnResizerContainer:hover .fixedDataTableCellLayout_columnResizerKnob {
  visibility: visible;
}

:global .fixedDataTable .fixedDataTableCellLayout_columnResizerKnob {
  position: absolute;
  right: 0;
  visibility: hidden;
  width: 4px;
}

:global .fixedDataTable .fixedDataTableColumnResizerLineLayout_mouseArea {
  cursor: ew-resize;
  position: absolute;
  right: -5px;
  width: 12px;
}

:global .fixedDataTable .fixedDataTableColumnResizerLineLayout_main {
  border-right-style: solid;
  border-right-width: 1px;
  box-sizing: border-box;
  position: absolute;
  z-index: 10;
}

:global .fixedDataTable .fixedDataTableColumnResizerLineLayout_hiddenElem {
  display: none !important;
}

:global .fixedDataTable .fixedDataTableLayout_main {
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

:global .fixedDataTable .fixedDataTableLayout_header,
:global .fixedDataTable .fixedDataTableLayout_hasBottomBorder {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

:global .fixedDataTable .fixedDataTableLayout_footer .public_fixedDataTableCell_main {
  border-top-style: solid;
  border-top-width: 1px;
}

:global .fixedDataTable .fixedDataTableLayout_rowsContainer {
  overflow: hidden;
  position: relative;
}

:global .fixedDataTable .fixedDataTableLayout_horizontalScrollbar {
  bottom: 0;
  position: absolute;
}

:global .fixedDataTable .fixedDataTableRowLayout_main {
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  top: 0;
  border-bottom: 1px solid $borderColor;
}

:global .fixedDataTable .fixedDataTableRowLayout_body {
  left: 0;
  position: absolute;
  top: 0;
}

:global .fixedDataTable .fixedDataTableRowLayout_fixedColumnsDivider {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-left-style: solid;
  border-left-width: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
}

:global .fixedDataTable .fixedDataTableRowLayout_rowWrapper {
  position: absolute;
  top: 0;
}

:global .fixedDataTable .ScrollbarLayout_main {
  box-sizing: border-box;
  outline: none;
  overflow: hidden;
  position: absolute;
  transition-duration: 250ms;
  transition-timing-function: ease;
  user-select: none;
}

:global .fixedDataTable .ScrollbarLayout_mainVertical {
  bottom: 0;
  right: 0;
  top: 0;
  transition-property: background-color width;
  width: 15px;
}

:global .fixedDataTable .ScrollbarLayout_mainVertical.public_Scrollbar_mainActive,
:global .fixedDataTable .ScrollbarLayout_mainVertical:hover {
  width: 17px;
}

:global .fixedDataTable .ScrollbarLayout_mainHorizontal {
  bottom: 0;
  height: 15px;
  left: 0;
  transition-property: background-color height;
}

/* Touching the scroll-track directly makes the scroll-track bolder */
:global .fixedDataTable .ScrollbarLayout_mainHorizontal.public_Scrollbar_mainActive,
:global .fixedDataTable .ScrollbarLayout_mainHorizontal:hover {
  height: 17px;
}

:global .fixedDataTable .ScrollbarLayout_face {
  left: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}

/**
 * This selector renders the "nub" of the scrollface. The nub must
 * be rendered as pseudo-element so that it won't receive any UI events then
 * we can get the correct `event.offsetX` and `event.offsetY` from the
 * scrollface element while dragging it.
 */
:global .fixedDataTable .ScrollbarLayout_face::after {
  border-radius: 6px;
  content: '';
  display: block;
  position: absolute;
  transition: background-color 250ms ease;
}

:global .fixedDataTable .ScrollbarLayout_faceHorizontal {
  bottom: 0;
  left: 0;
  top: 0;
}

:global .fixedDataTable .ScrollbarLayout_faceHorizontal::after {
  bottom: 4px;
  left: 0;
  top: 4px;
  width: 100%;
}

:global .fixedDataTable .ScrollbarLayout_faceVertical {
  left: 0;
  right: 0;
  top: 0;
}

:global .fixedDataTable .ScrollbarLayout_faceVertical::after {
  height: 100%;
  left: 4px;
  right: 4px;
  top: 0;
}

:global .fixedDataTable .public_fixedDataTable_main {
  border-color: $borderColor;
  border-top: none;
}

:global .fixedDataTable .public_fixedDataTable_header,
:global .fixedDataTable .public_fixedDataTable_hasBottomBorder {
  border-color: $borderColor;
}

:global .fixedDataTable .public_fixedDataTable_header .public_fixedDataTableCell_main {
  font-weight: 400;
}

:global .fixedDataTable .public_fixedDataTable_header,
:global .fixedDataTable .public_fixedDataTable_header .public_fixedDataTableCell_main {
  background-color: $headerBgColor !important;
}

:global .fixedDataTable .public_fixedDataTable_horizontalScrollbar .public_Scrollbar_mainHorizontal {
  background-color: $white;
}

/**
 * Table cell.
 */
:global .fixedDataTable .public_fixedDataTableCell_main {
  background-color: transparent;
}

:global .fixedDataTable .public_fixedDataTableCell_highlighted {
  background-color: $rowColor;
}

:global .fixedDataTable .public_fixedDataTableCell_cellContent {
  display: inline-flex;
  padding: 8px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
}

:global .fixedDataTable .public_fixedDataTableCell_columnResizerKnob {
  background-color: $blue500;
}

/**
 * Column resizer line.
 */
:global .fixedDataTable .public_fixedDataTableColumnResizerLine_main {
  border-color: $blue500;
}

:global .fixedDataTable .public_fixedDataTableRow_main {
  background-color: $rowColor;
}

:global .fixedDataTable .public_fixedDataTableRow_fixedColumnsDivider {
  border-color: $grey250;
}

:global .fixedDataTable .public_fixedDataTableRow_main.selectedRow,
:global .fixedDataTable .public_fixedDataTableRow_main.selectedRow:hover,
:global .fixedDataTable .fixedDataTableRowLayout_rowWrapper .selectedRow.public_fixedDataTable_bodyRow:hover .public_fixedDataTableCell_main,
:global .fixedDataTable .fixedDataTableRowLayout_rowWrapper .selectedRow.public_fixedDataTable_bodyRow:hover .public_fixedDataTableCell_main:hover {
  background-color: $rowSelectedColor !important;
  color: $textPrimary;
}

:global .fixedDataTable.submissionTable .public_fixedDataTableRow_main.selectedRow,
:global .fixedDataTable.submissionTable .public_fixedDataTableRow_main.selectedRow:hover,
:global .fixedDataTable.submissionTable .fixedDataTableRowLayout_rowWrapper .selectedRow.public_fixedDataTable_bodyRow:hover .public_fixedDataTableCell_main,
:global .fixedDataTable.submissionTable .fixedDataTableRowLayout_rowWrapper .selectedRow.public_fixedDataTable_bodyRow:hover .public_fixedDataTableCell_main:hover {
  background-color: $rowSelectedColorSubmissionManager !important;
  color: $textPrimary;
}

:global .fixedDataTable .public_fixedDataTableRow_main.archiving,
:global .fixedDataTable .public_fixedDataTableRow_main.archiving:hover,
:global .fixedDataTable .fixedDataTableRowLayout_rowWrapper .archiving.public_fixedDataTable_bodyRow:hover .public_fixedDataTableCell_main,
:global .fixedDataTable .fixedDataTableRowLayout_rowWrapper .archiving.public_fixedDataTable_bodyRow:hover .public_fixedDataTableCell_main:hover {
  background-color: $red50 !important;
  color: $textPrimary;
}

:global .fixedDataTable.submissionTable .public_fixedDataTableRow_main.archiving,
:global .fixedDataTable.submissionTable .public_fixedDataTableRow_main.archiving:hover,
:global .fixedDataTable.submissionTable .fixedDataTableRowLayout_rowWrapper .archiving.public_fixedDataTable_bodyRow:hover .public_fixedDataTableCell_main,
:global .fixedDataTable.submissionTable .fixedDataTableRowLayout_rowWrapper .archiving.public_fixedDataTable_bodyRow:hover .public_fixedDataTableCell_main:hover {
  background-color: $red50 !important;
  color: $textPrimary;
}

/**
 * Form Icons
 * .icon-submission-holder styles is used in /components/FormsTable/FormTypeIcon.jsx
 * it's for when the row is selected and can't be set in the specific css modules file
 */
:global .fixedDataTable.lobbyTable .public_fixedDataTableRow_main.selectedRow .icon-submission-holder {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
}

/**
 * Scrollbars.
 */

/* Touching the scroll-track directly makes the scroll-track bolder */
:global .fixedDataTable .public_Scrollbar_main.public_Scrollbar_mainActive,
:global .fixedDataTable .public_Scrollbar_main:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

:global .fixedDataTable .public_Scrollbar_mainOpaque,
:global .fixedDataTable .public_Scrollbar_mainOpaque.public_Scrollbar_mainActive,
:global .fixedDataTable .public_Scrollbar_mainOpaque:hover {
  background-color: $white;
}

:global .fixedDataTable .public_Scrollbar_face::after {
  background-color: $grey400;
}

:global .fixedDataTable .public_Scrollbar_main:hover .public_Scrollbar_face::after,
:global .fixedDataTable .public_Scrollbar_mainActive .public_Scrollbar_face::after,
:global .fixedDataTable .public_Scrollbar_faceActive::after {
  background-color: $grey600;
}
