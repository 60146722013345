@import "styles/shared/colors.css";

.triggerItemAppearance {
  align-items: center;
  display: flex;
  padding-left: 2px;

  svg {
    margin-right: 10px;
  }
}

.triggerItemIcon {
  fill: $grey650;
}
