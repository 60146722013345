
.headerContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 4.5rem;
  width: 100%;
  padding: 0 1.25rem;
  z-index: 1;
  box-shadow: 0 4px 10px 1px #ccc;
  background-color: #fff;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleAndLogoWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.headerLogo {
  display: block;
  width: 100%;
  max-width: 60px;
  height: 100%;
  max-height: 40px;
}

.headerTitle {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2.2rem;
  font-family: Inter, sans-serif;
  max-width: 24.375rem; /* 390px */
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
}

.editButton {
  padding: 0.5rem 1rem;
  color: #0052b2;
  border: 1px solid #0052b2;
  background-color: #fff;
  border-radius: 3px;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.editButton:hover,
.editButton:focus {
  background-color: #0052b2;
  color: #fff;
  outline: none;
}

.editButton:focus {
  outline: 2px solid #0052b2;
}

.linkNoDecoration {
  text-decoration: none;
}
