@import "styles/shared/colors.css";
@import "styles/shared/breakpoints.css";

.loginHolder {
  background: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.loginContainer {
  background-color: $white;
  display: flex;
  flex-direction: column;
  width: 520px;
}

.loginContainerHidden {
  composes: loginContainer;
  opacity: 0;
  top: 0;
  width: 0;
}

.loginContainerShown {
  composes: loginContainer;
  opacity: 1;
}

.loginForm {
  composes: form from "components/AuthLayout/styles.css";
  width: 425px;
}

.header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 40px;
}

.activeDirectoryButton {
  background-color: #004ce0;
  border: none;
  border-radius: 4px;
  height: 42px;
  line-height: 18px;
  margin: 0 8px 8px 0;
  min-width: 0;
  padding: 12px 4px;
}

.activeDirectoryButtonLabel {
  color: $white;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  padding-left: 16px;
  padding-right: 16px;
}

.companyLogoWrapper {
  display: flex;
  flex-direction: column;
  height: 96px;
  justify-content: center;
  margin-right: 24px;
  min-height: 96px;
  min-width: 96px;
  width: 96px;
}

.companyLogo {
  display: block;
  max-height: 96px;
  width: 96px;
}

.sdCompanyLogo {
  width: 96px;
}

.organizationName {
  align-self: center;

  h6 {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    span {
      font-weight: 500;
    }
  }

  h3 {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0.25px;
    line-height: 36px;
    margin: 0;
  }
}

.loginSocialWrapper {
  display: block;
  margin-top: 40px;
  text-align: center;
}

.socialLogo {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 16px 12px;
  margin-bottom: 0;
}

.socialLogo:hover {
  cursor: pointer;
}

.divider {
  composes: medium from "styles/shared/text.css";
  color: $textSecondary;
  display: block;
  margin: 16px 0;
}

.formWrapper {
  display: block;
}

.footer {
  align-content: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  padding-top: 16px;

  a {
    align-items: center;
    display: flex;
  }
}

.forgotPassword {
  font-size: 14px;
  text-decoration: none;
  color: $newPrimaryText;
  font-weight: 600;

  &:hover {
    color: $blue500;
  }
}

.formLabel {
  composes: inputLabel from "styles/shared/input.css";
}

.formInput {
  composes: inputField from "styles/shared/input.css";
  height: 48px;
  margin-bottom: 16px;
}

.formInputError {
  composes: formInput;
  composes: inputFieldError from "styles/shared/input.css";
}

.invisibleSubmit {
  composes: invisibleSubmit from 'styles/shared/input.css';
}

.showPasswordField {
  position: relative;

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    color: $buttonBlueBackground;
    background: $grey100;
    border-radius: 2px;
    font-size: 13px;
    width: 48px;
    cursor: pointer;
    border: 0;
    outline: none;
  }
}

.showPasswordFieldInput {
  composes: formInput;
}

.visitorContainer {
  background-color: $grey150;
  border-radius: 4px;
  color: $newPrimaryText;
  font-size: 14px;
  margin-top: 12px;
  padding: 16px;
  text-align: center;

  a {
    color: $newPrimaryText;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      color: $blue700;
    }
  }
}

.formResponsePadding {
  margin-top: 16px;
}

.orDivider {
  border-top: 1px solid $grey250;
  color: $black;
  height: 0;
  margin: 18px 0 26px;
  text-align: center;

  span {
    position: relative;
    top: -11px;
    background: $grey100;
    padding: 13px;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 600;
    margin: 10px;
  }
}

.activeDirectoryButtonList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
}

.brandedFooter {
  align-items: center;
  background-color: $white;
  bottom: 0;
  box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.12);
  color: $textSecondary;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  left: 0;
  padding: 12px 16px;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.brandedFooter a {
  text-decoration: none;
  color: $textSecondary;
}

.poweredByContainer {
  display: flex;
  align-items: center;
  margin-right: 150px;

  svg {
    height: 22px;
    margin-left: 6px;
    position: relative;
    width: 140px;
  }
}

.poweredByText {
  font-size: 13px;
  margin-top: 2px;
}

.submitRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: $breakpoint__small-xx) {
  .loginContainer {
    margin: 0;
    max-width: 100%;
    min-width: 0;
    padding: 20px;
  }

  .body {
    padding: 16px;
  }

  .brandedFooter {
    justify-content: flex-start;
    padding: 0;
    box-shadow: none;
  }

  .termsOfService {
    display: none;
  }

  .poweredBy {
    margin-left: 10px;
    margin-right: 0;
    margin-top: 0;

    img {
      width: 100px;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .brandedFooter {
    box-shadow: 0 -1px 10px 3px rgba(0, 0, 0, 0.12);
  }
}
