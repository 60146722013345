.tickerCard {
  position: relative;
  display: block;
  float: left;
  text-align: center;
  font-weight: normal;
  color: #fff;
  line-height: normal;
  text-transform: capitalize;
}

.tickerCard--lg {
  height: 148px;
  width: 144px;
  font-size: 18px;
  padding-top: 10px;
}

.tickerCard--md {
  font-size: 14px;
  height: 112px;
  width: 108px;
  padding-top: 8px;
}

.tickerCard--sm {
  font-size: 9px;
  height: 74px;
  width: 74px;
  padding-top: 5px;
}

.countdown {
  display: block;
}

.tickerCard--lg .countdown {
  font-size: 90px;
}

.tickerCard--md .countdown {
  font-size: 68px;
}

.tickerCard--sm .countdown {
  font-size: 45px;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: bottom left;
  background-repeat: no-repeat;
}

.tickerCard--lg .overlay {
  height: 148px;
}

.tickerCard--md .overlay {
  height: 111px;
}

.tickerCard--sm .overlay {
  height: 72px;
}
