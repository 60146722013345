@import "styles/shared/colors.css";

.container {
  display: flex;
  justify-content: space-around;
  padding: 16px 32px;
}

.flexItem {
  flex: 1;
}

.label {
  composes: small from "styles/shared/text.css";
  color: $textSecondary;
  margin-bottom: 0;
  display: block;
  padding-top: 8px;
}

.information {
  composes: medium from "styles/shared/text.css";
  color: $textPrimary;
  margin-top: 0;
  display: block;
  max-width: 256px;
  word-wrap: break-word;
  padding-bottom: 8px;

  img {
    width: 100%;
  }
}
