@import "styles/shared/colors.css";
@import "styles/shared/breakpoints.css";

.accountContainer {
  display: inline-block;
  margin-right: 1rem;
}

.accountContainer:hover {
  cursor: pointer;
}

.userName {
  composes: medium from "styles/shared/text.css";
  composes: ellipsis from "styles/shared/ellipsis.css";
  color: $textPrimary;
  margin-right: 4px;
  display: inline-block;
  max-width: 160px;
  vertical-align: middle;
}

.menuHead {
  padding: 16px;
  background-color: $orange500;
  color: $white;
}

.menuTitle {
  font-size: 16px;
  vertical-align: middle;
}

.createFormContainer {
  display: inline-block;
}

@media screen and (max-width: $breakpoint__phone-portrait) {
  .createFormContainer {
    display: none;
  }
}

.createFormPopover,
.accountMenuPopover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  margin-top: -16px;
}

.accountMenuPopover {
  width: 220px;
}

.createFormPopover {
  width: 300px;
}

.accountMenuHeaderItem {
  background-color: $grey800;
  color: $white;
  cursor: auto;
  font-size: 16px;
  height: auto;
  line-height: 54px;
  opacity: 1;
  padding-top: 0;
  padding-bottom: 0;
}

.accountMenuItem {
  color: $textPrimary;
  font-size: 14px;
  height: auto;
  line-height: 48px;
  padding-top: 0;
  padding-bottom: 0;
}

.accountMenuButton {
  composes: buttonReset from "styles/shared/resets.css";
  width: 100%;
}

.menuItemChildWrap {
  display: block;
  min-height: 60px;
  padding: 12px 32px 12px 56px;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 2;
}
